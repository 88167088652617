.sortable-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: inherit;
  border: 0;
  background: none;
  margin: 0;
}
.sorter-arrows {
  margin-left: 0.5rem;
  font-size: 1.1rem;
}
.unsorted-arrows {
  position: relative;
  color: var(--exo-palette-gray-70);
}
.unsorted-caret-up {
  position: absolute;
  top: -0.6em;
  width: 0.8em;
  height: 0.8em;
}
.unsorted-caret-down {
  position: absolute;
  top: -0.2em;
  width: 0.8em;
  height: 0.8em;
}
