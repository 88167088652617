#root .file-drop input[type='file'] {
  display: none;
}
#root .file-drop-target {
  border: 2px dashed #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}
#root .file-drop-target.active {
  border-color: var(--exo-color-border-highlight);
}
#root .file-drop-target.active * {
  /* prevent dragLeave events firing on child elements */
  pointer-events: none;
}
#root .disabled .file-drop-target {
  color: #ccc;
}
#root .file-drop-target-image {
  max-width: 100px;
  color: #ccc;
}
