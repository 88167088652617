#root .admin-page.import-profiles {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

.import-types {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    align-items: flex-start;
    gap: var(--exo-spacing-small);
    flex-shrink: 0;
}

.import-type {
    font-family: var(--exo-font-family);
    color: var(--exo-color-font);
    background-color: var(--exo-color-background);
    border: 1px solid var(--exo-color-border);
    box-shadow: 0 var(--exo-spacing-3x-small) var(--exo-spacing-2x-small)
        var(--exo-color-shadow-moderate);
    font-size: var(--exo-font-size-small);
    line-height: var(--exo-line-height-body);
    overflow: hidden;
    border-radius: var(--exo-radius-large);
    transition: background-color var(--exo-time-standard) ease;
    padding: var(--exo-spacing-standard) var(--exo-spacing-medium);
    display: flex;
    flex-direction: column;
    max-height: 100%;
    min-height: 400px;
    min-width: 400px;
}

.import-type .invalid-type {
    display: flex;
    align-items: center;
    color: var(--exo-color-font-danger);
    gap: var(--exo-spacing-x-small);
}

.import-profiles {
    .input-wide {
        max-width: 600px;
    }

    ex-select {
        margin-bottom: 1em;
    }
}
