.insights-content {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 1.8rem;
  margin-top: 2rem;
}
.insights-tile {
  width: 100%;
  border: 1px solid var(--exo-color-border);
  border-radius: var(--exo-radius-standard);
  background-color: var(--exo-color-background-action-secondary);
  padding: 10px 20px 20px 20px;
  text-align: start;
}
.insights-tile-hover {
  cursor: pointer;
  border: 1px solid var(--exo-color-border-action-hover);
  background-color: var(--exo-color-background-action-secondary-hover);
}
.insight-tile-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.insights-chart-wrapper {
  position: relative;
  width: 100%;
  min-width: 0px;
}
.chart-area {
  display: flex;
  align-items: baseline;
  border: 1px solid var(--exo-color-border);
  border-radius: var(--exo-radius-large);
  box-shadow: 0px 0px 3px 0px var(--exo-color-border);
  padding: var(--exo-spacing-large);
  justify-content: space-around;
  gap: 33px 0px;
}
.line-chart-section {
  width: 100%;
}
.pie-chart-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.insights-chart {
  height: 400px;
  width: 100%;
}
.insights-pie-chart {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
}
.insights-control-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.insights-control-group {
  display: flex;
  align-items: baseline;
}
.insights-search {
  width: 14rem;
  margin-right: 1rem;
}
.dash-pill {
  background: #e2efff;
  padding: 2px 5px;
  border-radius: 5px;
  margin-right: 5px;
  font-weight: 700;
}
.insight-date-filter-wrapper {
  display: flex;
}
.insight-date-filter-wrapper button {
  padding: var(--exo-spacing-2x-small) var(--exo-spacing-x-small);
  border: var(--exo-spacing-4x-small) solid var(--exo-color-border);
  outline: none;
  background-color: var(--exo-color-background);
  white-space: nowrap;
  margin-left: -1px;
}
.insight-date-filter-wrapper button.insights-date-filter-icon {
  cursor: default;
  font-size: var(--exo-font-size-3x-large);
  border-radius: 0 var(--exo-radius-standard) var(--exo-radius-standard) 0;
  color: var(--exo-color-font-disabled);
}
.insight-date-filter-wrapper button.insights-date-filter-btn {
  border-radius: 0;
  font: var(--exo-text-label-medium-semi-bold);
  gap: calc(var(--exo-spacing-4x-small) + var(--exo-spacing-3x-small));
}
.insight-date-filter-wrapper button.insights-date-filter-btn:hover,
.insight-date-filter-wrapper button.insights-date-filter-btn:focus {
  border-color: var(--exo-color-border-action-hover);
  background-color: var(--exo-color-background-action-secondary-hover);
  z-index: 1;
}
.insight-date-filter-wrapper:hover {
  z-index: 1;
}
.insight-date-filter-wrapper .insights-date-filter-btn:first-child {
  border-radius: var(--exo-radius-standard) 0 0 var(--exo-radius-standard);
}
.insight-date-filter-wrapper button.insights-date-filter-btn-active {
  cursor: default;
  background-color: var(--exo-color-background-selected);
  color: var(--exo-color-font-inverse);
}
.insight-date-filter-wrapper button.insights-date-filter-btn-active:hover {
  border-color: var(--exo-color-border);
  background-color: var(--exo-color-background-selected);
}
.insight-date-filter-wrapper button.insights-date-filter-btn-active:focus {
  border-color: var(--exo-color-border-action-hover);
  background-color: var(--exo-color-background-selected-hover);
}
.inights-filter-bar-control {
  min-width: 15rem;
}
.insights-empty-state {
  margin-top: 5rem;
}
.insights-date-range {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.insights-date-picker,
.insights-date-picker-small {
  border: none;
}
.insights-date-picker-small::-webkit-calendar-picker-indicator {
  margin-left: -43px;
}
.insights-date-picker::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
.insights-states-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}
#root .insights-control-bar .insights-search .insights-search-input {
  width: unset;
}
