#root .config-modal .modal-element-title {
  margin-top: 3px;
}
#root .config-modal .modal-header {
  padding-top: 20px;
}
#root .config-modal .modal-element-header {
  display: flex;
}
#root .config-modal .modal-element-top-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  border-radius: 6px 6px 0 0;
  content: '';
}
#root .config-modal .modal-element-icon-background {
  border-radius: 100%;
  padding: 3px;
  line-height: 1em;
  height: 33px;
  margin-right: 10px;
}
#root .config-modal .modal-element-icon {
  top: 0px;
  padding: 5px;
  border-radius: 100%;
  color: black;
  background: white;
}
#root .config-modal .modal-element-icon.glyphicon {
  display: flex;
}
#root .config-modal .modal-footer {
  background: whitesmoke;
  border-radius: 0 0 6px 6px;
}
#root .system-flow.modal-dialog > * > .modal-body {
  padding: 0;
  border-radius: 5px;
}
#root .system-flow.modal-dialog > * > .modal-body .flow {
  height: calc(100vh - 365px);
}
#root .system-flow.modal-dialog > * > .modal-body .flow .navbar {
  display: none;
}
#root .system-flow.modal-dialog > * > .modal-body .flow h2 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6875rem;
  margin: 0 0 25px;
  padding: 6px 5px;
}
#root .system-flow.modal-dialog > * > .modal-body .flow h2:after {
  position: relative;
  display: block;
  content: '';
  top: 20px;
  left: -50%;
  width: calc(150% + 20px);
  height: 1px;
  background: #e5e5e5;
}
#root .system-flow.modal-dialog > * > .modal-body .flow label:empty {
  display: none;
}
#root .system-flow.modal-dialog > * > .modal-body .flow .main-scroller {
  padding: 15px;
}
#root .system-flow.modal-dialog > * > .modal-body .flow .mw-container {
  margin-bottom: 0;
}
#root .system-flow.modal-dialog > * > .modal-body .flow .mw-presentation.pull-left {
  width: 100%;
}
