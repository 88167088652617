.tooltip-wrapper {
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  text-align: left;
  display: inline-block;
  position: relative;
}
.tooltip-content {
  padding: 6px 12px;
  border-radius: 10px;
  background-color: var(--exo-palette-gray-90);
  color: var(--exo-palette-white);
  max-width: 50rem;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}
.tooltip-content .tooltip-arrow {
  position: absolute;
  background: var(--exo-palette-gray-90);
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
}
.tooltip-content-wrapper {
  border: 1px solid #00000033;
  border-radius: var(--exo-radius-standard);
  box-shadow: 0 5px 10px #00000033;
  overflow: hidden;
}
.tooltip-content-wrapper .tooltip-content-header {
  background-color: #fafafa;
  border-bottom: 1px solid #eee;
  margin: 0;
  padding: 8px 14px;
}
.tooltip-content-wrapper .tooltip-content-body {
  font-size: 14px;
  padding: 10px;
  background: var(--exo-palette-white);
}
