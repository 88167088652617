#root .organization-invite-radio-button-container:last-child {
  margin-left: 0.5rem;
}
#root .organization-invite-radio-button-label {
  margin-left: 0.25rem;
}
#root .organization-invite-modal-footer {
  display: flex;
  justify-content: end;
}
