#root .theme-detail .theme-detail-layout {
  display: flex;
  gap: 40px;
}
#root .theme-detail .form-group {
  max-width: 48rem;
}
#root .theme-color-group {
  display: flex;
  gap: 15px;
  margin-top: var(--exo-spacing-medium);
}
#root .theme-detail-layout ex-input {
  margin-bottom: 1em;
}
#root .theme-color-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
  max-width: min-content;
}
#root .theme-color-input input[type='color'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  width: 80px;
  height: 80px;
  border: none;
  cursor: pointer;
}
#root .theme-color-input input[type='color']::-webkit-color-swatch {
  border-radius: 50%;
  border: 1px solid #ccc;
}
#root .theme-color-input input[type='color']::-moz-color-swatch {
  border-radius: 50%;
  border: 3px solid #000000;
}
#root .theme-tooltip {
  max-width: min-content;
}
#root .resource-list {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--exo-spacing-x-large);
}
#root .input-wide {
  width: 600px;
}
#root .input-med {
  width: 350px;
}
#root legend {
  font-size: 14px;
  border-bottom: none;
  margin-bottom: 15px;
  font-weight: 600;
}
#root .runtime-preview {
  flex: 1;
  position: relative;
}
#root .runtime-preview-frame {
  border: 1px solid #eeeeee;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: var(--exo-radius-standard);
  overflow: hidden;
  position: sticky;
  top: 0px;
}
#root .theme-font-select-items {
  max-height: 300px;
  overflow-y: auto;
}
#root .theme-help-text {
  margin-top: 5px;
  max-width: 350px;
  font-size: 0.7rem;
  color: var(--exo-color-background-info-strong);
}
#root .font-wrapper option {
  font-size: 1.05rem;
}
#root .code-container {
  padding: 10px 0;
  background-color: #141414;
}
