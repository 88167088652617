#root .value-selector-modal .modal-body {
  gap: var(--exo-spacing-small);
  padding: 0;
  overflow: hidden;
}
.value-selector-values-header {
  padding: var(--exo-spacing-standard);
  padding-bottom: 0;
}
.value-selector-values-search {
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: var(--exo-spacing-small);
  align-items: center;
}
#root .value-selector-values-search .search-control-wrapper {
  width: 100%;
}
.value-selector-filter-container {
  display: none;
}
.value-selector-filter-container.is-open {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto auto;
  gap: var(--exo-spacing-small);
}
.value-selector-filter-toggle {
  justify-self: start;
  color: #008cba;
  border: 0 none;
  background: none;
  padding: 0;
  margin: 0.5em 0 0;
}
#root .value-selector-filter-type {
  grid-column: 1 / 3;
  margin-bottom: 0;
}
.value-selector-filter-content-type {
  grid-column: 1 / 3;
}
.value-selector-filter-access {
  grid-row: 3 / 4;
}
.value-selector-filter-is-fixed {
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  align-self: center;
}
#root .value-selector-filter-container .form-group {
  margin-bottom: 0;
}
.value-selector-values-list {
  padding: var(--exo-spacing-standard);
}
.value-selector-values-list > h5 {
  margin-top: 0;
}
.value-selector-values-list ul {
  list-style-type: none;
  padding-left: 0;
}
.value-selector-values-list ul li {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto auto;
  margin-bottom: var(--exo-spacing-small);
}
.value-selector-values-option {
  border: 0;
  background: none;
  padding: var(--exo-spacing-2x-small);
  text-align: left;
  margin-right: var(--exo-spacing-2x-small);
}
.value-selector-values-option:hover {
  background: #f5f5f5;
}
.value-selector-values-list .value-selector-values-option {
  margin-bottom: var(--exo-spacing-2x-small);
}
.value-selector-values-option-name {
  display: block;
  font-size: var(--exo-font-size-small);
  margin-bottom: var(--exo-spacing-2x-small);
}
.value-selector-values-option-meta {
  display: block;
  font-size: var(--exo-font-size-x-small);
  color: var(--exo-palette-gray-60);
  max-width: 45ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.value-selector-values-option-edit {
  grid-row: 2 / 3;
  color: #008cba;
  border: 0 none;
  background: none;
  padding: 0;
  justify-self: start;
  margin-left: var(--exo-spacing-2x-small);
}
.value-selector-values-more-results {
  color: #008cba;
  border: 0 none;
  background: none;
  padding: 0;
  justify-self: start;
}
.value-selector-modal-cancel {
  justify-self: end;
}
.value-selector-modal .modal-footer {
  display: flex;
  justify-content: end;
}
.value-selector {
  width: 265px;
  display: block;
}
.value-selector-custom {
  /* A transparent container button to accommodate custom styled picker button content */
  border: none;
  background: none;
  display: block;
  padding: 0;
  margin-left: 5px;
}
.input-value-picker {
  display: grid;
  grid-template-columns: 1fr max-content;
}
.input-value-picker-tooltip-wrapper {
  height: 100%;
}
#root .value-selector-custom .input-value-picker-button {
  padding: 0px 8px;
}
.value-selector-selected {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  gap: var(--exo-spacing-small);
  border: 1px solid #cccccc;
  padding: var(--exo-spacing-small);
}
.value-selector-values-list ul li .automapping-type-map {
  grid-row: 3 / 4;
  grid-column: 1 / 4;
  padding: var(--exo-spacing-standard) 0;
}
