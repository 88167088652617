.metadata-editor .modal-body {
    display: flex;
    flex-grow: 1;
    height: 600px;
    flex-direction: column;
}

.metadata-editor .ace_editor {
    flex-grow: 1;
    margin-bottom: 1em;
}

.metadata-editor > span {
    margin-top: 1em;
}

.metadata-editor .modal-body > .alert:last-child {
    margin-bottom: 0;
}
