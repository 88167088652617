#root .note-wrapper {
  word-wrap: break-word;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: var(--exo-radius-standard);
}
#root .note-wrapper .note-inner {
  width: 300px;
  padding: 0.5em;
}
#root .note-wrapper .note-inner textarea {
  min-width: 300px;
}
#root .note-wrapper .note-inner.note-inner-form {
  width: auto;
  min-width: 300px;
}
#root .note-wrapper h1 {
  font-size: 16px;
  margin: 0;
  flex-grow: 1;
  line-height: 1.3rem;
}
#root .note-wrapper .note-header {
  display: flex;
  justify-content: space-between;
}
#root .note-wrapper .note-footer {
  border-top: 1px solid #cccccc;
  padding: 0.8em 0.5em;
  font-size: 11px;
  display: flex;
  max-width: 300px;
}
#root .note-wrapper .note-footer .footer-column {
  width: 50%;
}
#root .note-wrapper .note-footer .footer-column.created-at {
  text-align: right;
}
#root .note-wrapper .note-content {
  margin-top: 1em;
}
#root .note-wrapper .note-controls {
  position: relative;
}
#root .note-wrapper .note-controls-dropdown {
  display: none;
  position: absolute;
  right: 0;
  padding: 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: var(--exo-radius-standard);
  width: 70px;
}
#root .note-wrapper .note-control-btn {
  border: 0 none;
  background: none;
  padding: 0.3em;
  width: 100%;
  text-align: left;
}
#root .note-wrapper .note-control-btn:hover {
  background: var(--exo-palette-aqua-30);
}
#root .note-wrapper .note-controls-dropdown.open {
  display: block;
}
#root .note-wrapper .note-controls-cog {
  padding: 0px 1px;
}
#root .note-wrapper input.form-control {
  height: 25px;
}
#root .note-wrapper .form-group {
  margin-bottom: 10px;
}
#root .note-wrapper .btn {
  height: 25px;
}
#root .form-field {
  margin-bottom: 0.5em;
}
#root .note-form-footer {
  display: flex;
  justify-content: flex-end;
}
