#root .nextgen-page-builder .main-wrapper {
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'sidebar_L toolbar toolbar' 'sidebar_L composer sidebar_R';
  overflow: hidden;
}
#root .nextgen-page-builder .classic-composer,
#root .nextgen-page-builder .grid-composer {
  grid-area: composer;
  overflow: hidden;
  position: relative;
}
#root .nextgen-page-builder .sidebar-left {
  display: grid;
  grid-template-columns: 1fr;
  margin-right: var(--exo-spacing-standard);
  grid-area: sidebar_L;
}
#root .nextgen-page-builder .component-group {
  border: 1px solid var(--exo-palette-gray-30);
  border-radius: var(--exo-radius-standard);
  margin-bottom: var(--exo-spacing-standard);
  background-color: var(--exo-palette-white);
  overflow: hidden;
}
#root .nextgen-page-builder .component-group .picker-button {
  padding: var(--exo-spacing-x-small);
}
#root .nextgen-page-builder .component-group .picker-button button {
  width: 100%;
}
#root .nextgen-page-builder .sidebar-left.preview {
  display: none;
}
#root .nextgen-page-builder .sidebar-right {
  grid-area: sidebar_R;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'sidebar_R_Resize sidebar_R_Content' 'sidebar_R_Footer sidebar_R_Footer';
  overflow: auto;
  width: var(--sidebar-R-min-size);
  margin: var(--exo-spacing-standard) var(--exo-spacing-standard) var(--exo-spacing-standard) var(--exo-spacing-none);
  background-color: var(--exo-palette-white);
  border-radius: var(--exo-radius-standard);
  border: 1px solid var(--exo-palette-gray-30);
}
#root .nextgen-page-builder .sidebar-right.preview {
  display: none;
}
#root .nextgen-page-builder .sidebar-left-panels {
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: var(--exo-radius-standard);
}
#root .nextgen-page-builder .sidebar-left-panels::-webkit-scrollbar {
  display: none;
}
#root .nextgen-page-builder .component-group-heading {
  font: var(--exo-text-h7);
  line-height: 1;
  padding: var(--exo-spacing-x-small);
  border-bottom: 1px solid var(--exo-palette-gray-30);
  text-align: center;
  background-color: var(--exo-palette-gray-10);
}
#root .nextgen-page-builder .sidebar-left .value-selector {
  width: auto;
}
#root .nextgen-page-builder {
  /*
    // WIP
    // If the focus is elsewhere desaturate the grid as an indication that the
    // focus is elsewhere.
    .grid-composer {
        .composer {
            filter: saturate(0);
        }
    }
    
    // Restore saturation if the focus is within the grid composer.
    .grid-composer:focus-within {
        .composer {
            filter: saturate(1);
        }
    }
    */
}
#root .nextgen-page-builder .grid-composer {
  margin: var(--exo-spacing-standard);
  border: 1px solid var(--exo-palette-gray-30);
  border-radius: var(--exo-radius-standard);
  box-shadow: var(--exo-box-shadow-weak);
  overflow: hidden;
  background-color: var(--exo-palette-white);
  display: grid;
  grid-template-rows: auto 1fr;
}
#root .nextgen-page-builder .composer {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
#root .nextgen-page-builder .composer .grid {
  display: grid;
  grid-auto-rows: minmax(64px, auto);
  gap: var(--exo-spacing-x-small);
  padding: var(--exo-spacing-medium);
  align-items: start;
  align-content: start;
  margin: var(--exo-spacing-none);
  background: var(--exo-palette-white);
}
#root .nextgen-page-builder .composer .grid.columns-1 {
  grid-template-columns: 1fr;
}
#root .nextgen-page-builder .composer .grid.columns-2 {
  grid-template-columns: repeat(2, 1fr);
}
#root .nextgen-page-builder .composer .grid.columns-3 {
  grid-template-columns: repeat(3, 1fr);
}
#root .nextgen-page-builder .composer .grid.columns-4 {
  grid-template-columns: repeat(4, 1fr);
}
#root .nextgen-page-builder .composer .grid.columns-5 {
  grid-template-columns: repeat(5, 1fr);
}
#root .nextgen-page-builder .composer .grid.columns-6 {
  grid-template-columns: repeat(6, 1fr);
}
#root .nextgen-page-builder .composer .grid.columns-7 {
  grid-template-columns: repeat(7, 1fr);
}
#root .nextgen-page-builder .composer .grid.columns-8 {
  grid-template-columns: repeat(8, 1fr);
}
#root .nextgen-page-builder .composer .grid.columns-9 {
  grid-template-columns: repeat(9, 1fr);
}
#root .nextgen-page-builder .composer .grid.columns-10 {
  grid-template-columns: repeat(10, 1fr);
}
#root .nextgen-page-builder .composer .grid.columns-11 {
  grid-template-columns: repeat(11, 1fr);
}
#root .nextgen-page-builder .composer .grid.columns-12 {
  grid-template-columns: repeat(12, 1fr);
}
#root .nextgen-page-builder .composer .component {
  width: 100%;
  height: auto;
}
#root .nextgen-page-builder .composer .component.col-span-1 {
  grid-column: span 1 / auto;
}
#root .nextgen-page-builder .composer .component.col-span-2 {
  grid-column: span 2 / auto;
}
#root .nextgen-page-builder .composer .component.col-span-3 {
  grid-column: span 3 / auto;
}
#root .nextgen-page-builder .composer .component.col-span-4 {
  grid-column: span 4 / auto;
}
#root .nextgen-page-builder .composer .component.col-span-5 {
  grid-column: span 5 / auto;
}
#root .nextgen-page-builder .composer .component.col-span-6 {
  grid-column: span 6 / auto;
}
#root .nextgen-page-builder .composer .component.col-span-7 {
  grid-column: span 7 / auto;
}
#root .nextgen-page-builder .composer .component.col-span-8 {
  grid-column: span 8 / auto;
}
#root .nextgen-page-builder .composer .component.col-span-9 {
  grid-column: span 9 / auto;
}
#root .nextgen-page-builder .composer .component.col-span-10 {
  grid-column: span 10 / auto;
}
#root .nextgen-page-builder .composer .component.col-span-11 {
  grid-column: span 11 / auto;
}
#root .nextgen-page-builder .composer .component.col-span-12 {
  grid-column: span 12 / auto;
}
#root .nextgen-page-builder .composer .component.row-span-1 {
  grid-row: span 1 / auto;
}
#root .nextgen-page-builder .composer .component.row-span-2 {
  grid-row: span 2 / auto;
}
#root .nextgen-page-builder .composer .component.row-span-3 {
  grid-row: span 3 / auto;
}
#root .nextgen-page-builder .composer .component.row-span-4 {
  grid-row: span 4 / auto;
}
#root .nextgen-page-builder .composer .component.row-span-5 {
  grid-row: span 5 / auto;
}
#root .nextgen-page-builder .composer .component.row-span-6 {
  grid-row: span 6 / auto;
}
#root .nextgen-page-builder .composer .component.row-span-7 {
  grid-row: span 7 / auto;
}
#root .nextgen-page-builder .composer .component.row-span-8 {
  grid-row: span 8 / auto;
}
#root .nextgen-page-builder .composer .component.row-span-9 {
  grid-row: span 9 / auto;
}
#root .nextgen-page-builder .composer .component.row-span-10 {
  grid-row: span 10 / auto;
}
#root .nextgen-page-builder .composer .component.row-span-11 {
  grid-row: span 11 / auto;
}
#root .nextgen-page-builder .composer .component.row-span-12 {
  grid-row: span 12 / auto;
}
#root .nextgen-page-builder .composer .cell {
  align-self: stretch;
  justify-self: stretch;
  display: grid;
  background-color: var(--custom-light-blue);
  border: 1px solid var(--exo-palette-blue-40);
}
#root .nextgen-page-builder .composer .cell:hover {
  outline: 2px dashed var(--exo-palette-blue-40);
}
#root .nextgen-page-builder .composer .cell.selected,
#root .nextgen-page-builder .composer .cell.selected:hover {
  outline: 2px solid var(--exo-palette-blue-40);
}
#root .nextgen-page-builder .composer .cell.selected:after,
#root .nextgen-page-builder .composer .cell.selected:hover:after {
  content: 'place a component here';
  color: var(--exo-palette-blue-40);
  align-self: center;
  justify-self: center;
}
#root .nextgen-page-builder .composer .component {
  display: grid;
  padding: var(--exo-spacing-x-small);
  position: relative;
  overflow: hidden;
  background-color: var(--exo-palette-white);
  border: 1px solid var(--exo-palette-blue-40);
}
#root .nextgen-page-builder .composer .component.row-span-2,
#root .nextgen-page-builder .composer .component.row-span-3,
#root .nextgen-page-builder .composer .component.row-span-4,
#root .nextgen-page-builder .composer .component.row-span-5,
#root .nextgen-page-builder .composer .component.row-span-6,
#root .nextgen-page-builder .composer .component.row-span-7,
#root .nextgen-page-builder .composer .component.row-span-8,
#root .nextgen-page-builder .composer .component.row-span-9,
#root .nextgen-page-builder .composer .component.row-span-10,
#root .nextgen-page-builder .composer .component.row-span-11,
#root .nextgen-page-builder .composer .component.row-span-12 {
  align-self: normal;
}
#root .nextgen-page-builder .composer .component:hover {
  outline: 2px dashed var(--exo-palette-blue-40);
}
#root .nextgen-page-builder .composer .component.selected,
#root .nextgen-page-builder .composer .component.selected:hover {
  outline: 2px solid var(--exo-palette-blue-40);
}
#root .nextgen-page-builder .config-warning {
  padding: 2px;
  font-size: var(--exo-font-size-large);
  color: var(--exo-palette-yellow-40);
  background-color: var(--exo-palette-white);
  border-radius: var(--exo-radius-large);
  position: absolute;
  top: -12px;
  right: -12px;
}
#root .nextgen-page-builder .header-icon-button {
  padding: var(--exo-spacing-none);
  line-height: 0;
  min-height: 0;
  min-width: 0;
  color: var(--exo-palette-aqua-60);
}
#root .nextgen-page-builder .composer-context-menu {
  display: flex;
  visibility: hidden;
  position: fixed;
  color: var(--exo-palette-gray-90);
  background-color: var(--exo-palette-gray-10);
  border: 1px solid var(--exo-palette-gray-30);
  box-shadow: var(--exo-box-shadow-strong);
  border-radius: var(--exo-radius-small);
  overflow: hidden;
}
#root .nextgen-page-builder .composer-context-menu.is-open {
  visibility: visible;
  flex-flow: column nowrap;
}
#root .nextgen-page-builder .composer-context-menu-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: var(--exo-spacing-x-small);
  width: 100%;
  padding: var(--exo-spacing-x-small);
  cursor: pointer;
  user-select: none;
  color: var(--exo-palette-gray-90);
  text-align: start;
  border: none;
  outline: none;
  background-color: transparent;
}
#root .nextgen-page-builder .composer-context-menu-item:focus,
#root .nextgen-page-builder .composer-context-menu-item:hover {
  color: var(--exo-palette-white);
  background-color: var(--exo-palette-gray-50);
}
#root .nextgen-page-builder .composer-context-menu-item .menu-item-icon {
  flex: 0 0 20px;
  font-size: var(--exo-font-size-x-large);
}
#root .nextgen-page-builder .composer-context-menu-item .menu-item-label {
  flex: 1 1 auto;
  font-size: var(--exo-font-size-small);
}
#root .nextgen-page-builder .composer-context-menu-item .menu-item-shortcut {
  margin-left: calc(var(--exo-spacing-x-small) * 3);
  font-size: var(--exo-font-size-micro);
  opacity: 0.66;
}
#root .nextgen-page-builder .composer-context-menu-separator {
  margin: var(--exo-spacing-none);
  border: 0;
  border-top: 1px solid var(--exo-palette-gray-30);
}
#root .nextgen-page-builder .banner {
  align-self: center;
  padding: var(--exo-spacing-2x-small) var(--exo-spacing-x-small);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: var(--exo-spacing-x-small);
}
#root .nextgen-page-builder .banner.info {
  color: var(--exo-palette-blue-40);
}
#root .nextgen-page-builder .banner.warning {
  color: var(--exo-palette-yellow-40);
}
#root .nextgen-page-builder .banner.error {
  color: var(--exo-palette-red-60);
}
#root .nextgen-page-builder .banner.success {
  color: var(--exo-palette-green-60);
}
#root .nextgen-page-builder .banner .banner-icon {
  animation: shake-with-delay 10s linear infinite;
  animation-delay: 10s;
}
#root .nextgen-page-builder .banner .banner-message {
  display: inline;
  line-height: 1;
  margin: var(--exo-spacing-none);
}
@keyframes shake-with-delay {
  from,
  8.25%,
  to {
    transform: translate3d(0, 0, 0) scale(1, 1);
  }
  1.5%,
  3%,
  4.5%,
  6%,
  7.5% {
    transform: translate3d(0, -3px, 0) scale(0.9, 1.1);
  }
  2.25%,
  3.75%,
  5.25%,
  6.75% {
    transform: translate3d(0, 3px, 0) scale(1.1, 0.9);
  }
}
#root .nextgen-page-builder .page-toolbar {
  grid-area: toolbar;
  display: grid;
  grid-auto-columns: 1fr auto auto;
  grid-auto-rows: auto;
  grid-auto-flow: column;
  grid-gap: var(--exo-spacing-x-small);
  padding: var(--exo-spacing-x-small);
  background-color: var(--exo-palette-white);
  border-bottom: 1px solid var(--exo-palette-gray-30);
}
#root .nextgen-page-builder .page-toolbar-section {
  display: grid;
  justify-items: start;
  align-items: center;
  position: relative;
  grid-gap: var(--exo-spacing-x-small);
}
#root .nextgen-page-builder .page-toolbar-section.page-info {
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  padding-right: var(--exo-spacing-x-small);
}
#root .nextgen-page-builder .page-toolbar-section.page-actions {
  display: flex;
  flex-flow: row nowrap;
}
#root .nextgen-page-builder .page-info .page-name-input {
  width: 100%;
  height: var(--exo-spacing-x-large);
  padding: var(--exo-spacing-x-small) var(--exo-spacing-small);
  font-size: var(--exo-font-size-medium);
  font-weight: var(--exo-font-weight-semi-bold);
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: var(--exo-radius-standard);
  box-shadow: none;
  transition: none;
  outline: 0;
}
#root .nextgen-page-builder .page-info .page-name-input:hover,
#root .nextgen-page-builder .page-info .page-name-input:focus {
  background-color: white;
  border-color: var(--exo-palette-gray-30);
}
#root .nextgen-page-builder .page-info .page-name-input:focus {
  border-color: var(--exo-palette-blue-40);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
}
#root .nextgen-page-builder .grid-toolbar {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: start;
  grid-gap: var(--exo-spacing-x-small);
  padding: var(--exo-spacing-x-small);
  background-color: var(--exo-palette-gray-10);
  border-bottom: 1px solid var(--exo-palette-gray-30);
}
#root .nextgen-page-builder .grid-toolbar-section {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-rows: auto;
  grid-auto-flow: column;
  align-items: center;
}
#root .nextgen-page-builder .grid-toolbar-section.grid-columns,
#root .nextgen-page-builder .grid-toolbar-section.grid-rows {
  border-right: 1px solid var(--exo-palette-gray-30);
  padding-right: var(--exo-spacing-x-small);
}
#root .nextgen-page-builder .grid-toolbar-section.grid-columns,
#root .nextgen-page-builder .grid-toolbar-section.grid-rows,
#root .nextgen-page-builder .grid-toolbar-section.grid-general {
  padding-left: var(--exo-spacing-2x-small);
}
#root .nextgen-page-builder .grid-toolbar-section .section-label {
  margin: var(--exo-spacing-none) var(--exo-spacing-2x-small) var(--exo-spacing-none) var(--exo-spacing-none);
}
#root .nextgen-page-builder .sidebar-right-content {
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100%;
  width: 100%;
  gap: var(--exo-spacing-medium);
  padding: var(--exo-spacing-medium);
  padding-left: var(--exo-spacing-2x-small);
  grid-area: sidebar_R_Content;
}
#root .nextgen-page-builder .sidebar-right-footer {
  grid-area: sidebar_R_Footer;
}
#root .nextgen-page-builder .sidebar-main-heading {
  margin: var(--exo-spacing-none);
  font: var(--exo-text-h5);
  line-height: 1;
}
#root .nextgen-page-builder .sidebar-section-heading {
  margin: var(--exo-spacing-none);
  font: var(--exo-text-h7);
  line-height: 1;
}
#root .nextgen-page-builder .sidebar-section {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--exo-spacing-small);
  position: relative;
}
#root .nextgen-page-builder .sidebar-section:not(:first-of-type) .sidebar-section-heading,
#root .nextgen-page-builder .sidebar-section-heading + .sidebar-section-heading {
  margin-top: var(--exo-spacing-small);
}
#root .nextgen-page-builder .sidebar-section-content {
  gap: var(--exo-spacing-small);
}
#root .nextgen-page-builder .sidebar-section-content.flex-row {
  display: flex;
  flex-flow: row wrap;
}
#root .nextgen-page-builder .sidebar-section-content.flex-column {
  display: flex;
  flex-flow: column nowrap;
}
#root .nextgen-page-builder .sidebar-section-content.justify-right {
  display: flex;
  justify-items: flex-end;
}
#root .nextgen-page-builder .sidebar-section-content.grid-auto {
  --grid-layout-gap: var(--exo-spacing-small);
  --grid-column-count-max: 3;
  --grid-item-min-width: 128px;
  --gap-count: calc(var(--grid-column-count-max) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item-max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count-max));
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(), 1fr));
  grid-gap: var(--grid-layout-gap);
  align-items: stretch;
}
#root .nextgen-page-builder .sidebar-section-content.grid-auto > * {
  max-width: unset;
}
#root .nextgen-page-builder .sidebar-section-content:not(:last-of-type) {
  padding-bottom: var(--exo-spacing-small);
}
#root .nextgen-page-builder .sidebar-section-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: end;
  background-color: var(--exo-palette-gray-10);
  padding: var(--exo-spacing-small);
  border-top: 1px solid var(--exo-palette-gray-30);
}
#root .nextgen-page-builder .sidebar-mini-editor {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--exo-spacing-small);
  background-color: var(--exo-palette-white);
  padding: var(--exo-spacing-small);
  border: 1px solid var(--exo-palette-gray-30);
  border-radius: var(--exo-radius-standard);
}
#root .nextgen-page-builder .sidebar-placeholder {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
}
#root .nextgen-page-builder .sidebar-placeholder .placeholder-rocket {
  width: 75px;
  height: auto;
  margin: var(--exo-spacing-none) auto var(--exo-spacing-standard) auto;
  animation: rocket 10s linear;
  animation-delay: 10s;
}
#root .nextgen-page-builder .sidebar-placeholder .placeholder-message {
  font-size: var(--exo-font-size-small);
  margin: var(--exo-spacing-none) auto;
  padding: var(--exo-spacing-none) var(--exo-spacing-x-large);
}
#root .nextgen-page-builder .sidebar-placeholder .placeholder-message ul {
  padding: var(--exo-spacing-standard) var(--exo-spacing-x-large);
}
#root .nextgen-page-builder .sidebar-resize {
  display: block;
  border: 0 none;
  border-left: transparent solid 3px;
  background-color: transparent;
  padding: var(--exo-spacing-none);
  cursor: col-resize;
  grid-area: sidebar_R_Resize;
}
#root .nextgen-page-builder .sidebar-resize:hover {
  border-left: var(--exo-palette-navy-50) solid 3px;
}
#root .nextgen-page-builder .component-explorer-item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: 'icon label warning' 'shortcut shortcut shortcut';
  align-items: center;
  column-gap: var(--exo-spacing-x-small);
  row-gap: var(--exo-spacing-2x-small);
  padding: var(--exo-spacing-x-small);
  cursor: pointer;
  user-select: none;
  color: var(--exo-palette-navy-80);
  text-align: start;
  background-color: var(--exo-palette-white);
  border: 1px solid var(--exo-palette-navy-80);
  border-radius: var(--exo-radius-small);
  outline: none;
}
#root .nextgen-page-builder .component-explorer-item:disabled {
  cursor: not-allowed;
  background: var(--exo-palette-gray-30);
}
#root .nextgen-page-builder .component-explorer-item:focus:not(:disabled),
#root .nextgen-page-builder .component-explorer-item:hover:not(:disabled) {
  background-color: var(--exo-palette-aqua-30);
  border-color: var(--exo-palette-navy-80);
}
#root .nextgen-page-builder .component-explorer-item .explorer-item-icon {
  grid-area: icon;
  font-size: var(--exo-font-size-x-large);
}
#root .nextgen-page-builder .component-explorer-item .explorer-item-label {
  grid-area: label;
  font-size: var(--exo-font-size-small);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: var(--exo-line-height-body);
}
#root .nextgen-page-builder .component-explorer-item .explorer-item-warning {
  grid-area: warning;
  font-size: var(--exo-font-size-x-large);
}
#root .nextgen-page-builder .component-explorer-item .explorer-item-shortcut {
  justify-self: end;
  grid-area: shortcut;
  font-size: var(--exo-font-size-micro);
  opacity: var(--exo-opacity-5);
}
@keyframes rocket {
  0% {
    transform: translate3d(0, 0, 0) scale(1, 1);
    opacity: 1;
  }
  1.5%,
  3%,
  4.5%,
  6%,
  7.5% {
    transform: translate3d(0, -3px, 0) scale(0.9, 1.1);
  }
  2.25%,
  3.75%,
  5.25%,
  6.75% {
    transform: translate3d(0, 3px, 0) scale(1.1, 0.9);
  }
  7% {
    transform: translate3d(0, 0, 0) scale(1, 1);
  }
  15% {
    transform: translate3d(0, -1200px, 0);
    opacity: 1;
  }
  15.25% {
    opacity: 0;
  }
  15.5% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  17% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  19% {
    opacity: 1;
  }
}
#root .nextgen-page-builder .group {
  display: flex;
  flex-flow: column nowrap;
}
#root .nextgen-page-builder .group .group-heading {
  margin: var(--exo-spacing-none);
  padding: var(--exo-spacing-x-small);
  font: var(--exo-text-sub-heading-standard-semi-bold);
  color: var(--exo-palette-gray-90);
  background: linear-gradient(180deg, var(--exo-palette-gray-10) 0%, var(--exo-palette-gray-20) 100%);
}
#root .nextgen-page-builder .group .group-message {
  margin: var(--exo-spacing-none);
  padding: var(--exo-spacing-x-small);
  background-color: var(--exo-palette-white);
}
#root .nextgen-page-builder .group-list {
  display: flex;
  flex-flow: column nowrap;
  padding: var(--exo-spacing-none);
  margin: var(--exo-spacing-none);
}
#root .nextgen-page-builder .group-item {
  display: flex;
  flex-flow: row nowrap;
  gap: var(--exo-spacing-x-small);
  align-items: center;
  justify-content: start;
  padding: var(--exo-spacing-2x-small);
  background-color: var(--exo-palette-white);
}
#root .nextgen-page-builder .group-item:not(:last-of-type) {
  border-bottom: 1px solid var(--exo-palette-gray-20);
}
#root .nextgen-page-builder .item-info,
#root .nextgen-page-builder .item-actions {
  display: flex;
  flex-flow: row nowrap;
  padding: var(--exo-spacing-none);
  margin: var(--exo-spacing-none);
}
#root .nextgen-page-builder .item-actions {
  display: flex;
  flex-flow: row nowrap;
  padding: var(--exo-spacing-none);
  margin: var(--exo-spacing-none);
}
#root .nextgen-page-builder .item-info {
  align-items: center;
  margin-left: var(--exo-spacing-2x-small);
  gap: var(--exo-spacing-2x-small);
  line-height: 1.25;
}
#root .nextgen-page-builder .item-column,
#root .nextgen-page-builder .item-value,
#root .nextgen-page-builder .item-order {
  font-weight: var(--exo-font-weight-bold);
}
#root .nextgen-page-builder .formatted-condition-summary .summary-keyword,
#root .nextgen-page-builder .formatted-rule-summary .summary-keyword,
#root .nextgen-page-builder .formatted-operation-summary .summary-keyword {
  font-weight: var(--exo-font-weight-bold);
}
#root .nextgen-page-builder .formatted-condition-summary .summary-name,
#root .nextgen-page-builder .formatted-rule-summary .summary-name,
#root .nextgen-page-builder .formatted-operation-summary .summary-name {
  font-weight: var(--exo-font-weight-semi-bold);
}
#root .nextgen-page-builder .component-preview-input,
#root .nextgen-page-builder .component-preview-select,
#root .nextgen-page-builder .component-preview-textarea,
#root .nextgen-page-builder .component-preview-table,
#root .nextgen-page-builder .component-preview-payment-card,
#root .nextgen-page-builder .component-preview-file-upload,
#root .nextgen-page-builder .component-preview-richtext,
#root .nextgen-page-builder .component-preview {
  display: flex;
  flex-flow: column nowrap;
  pointer-events: none;
  user-select: none;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-size: 16px;
  --color-font: #222;
  --color-background: #fff;
  --color-default-primary: #033d58;
  --color-default-secondary: #ff7c66;
  --color-border: var(--color-font);
  --color-border-table: #ddd;
  --color-table-row-even: #f2f2f2;
  --color-table-row-hover: #ddd;
  --color-nav-active: #d8d8d8;
  --color-nav-hover: #ececec;
  background-color: var(--color-background);
}
#root .nextgen-page-builder .component-preview-input input,
#root .nextgen-page-builder .component-preview-select input,
#root .nextgen-page-builder .component-preview-textarea input,
#root .nextgen-page-builder .component-preview-payment-card input,
#root .nextgen-page-builder .component-preview-table input,
#root .nextgen-page-builder .component-preview-input select,
#root .nextgen-page-builder .component-preview-select select,
#root .nextgen-page-builder .component-preview-textarea select,
#root .nextgen-page-builder .component-preview-payment-card select,
#root .nextgen-page-builder .component-preview-table select,
#root .nextgen-page-builder .component-preview-input textarea,
#root .nextgen-page-builder .component-preview-select textarea,
#root .nextgen-page-builder .component-preview-textarea textarea,
#root .nextgen-page-builder .component-preview-payment-card textarea,
#root .nextgen-page-builder .component-preview-table textarea {
  border: 1px solid var(--color-border);
  border-radius: var(--exo-radius-standard);
  padding: calc(var(--font-size) / 2) calc(var(--font-size) * 0.75);
  font-size: var(--font-size);
  line-height: 1;
  color: var(--color-font);
  background-color: transparent;
  flex: 1;
}
#root .nextgen-page-builder .component-preview-input input,
#root .nextgen-page-builder .component-preview-select input,
#root .nextgen-page-builder .component-preview-textarea input,
#root .nextgen-page-builder .component-preview-payment-card input,
#root .nextgen-page-builder .component-preview-table input,
#root .nextgen-page-builder .component-preview-input select,
#root .nextgen-page-builder .component-preview-select select,
#root .nextgen-page-builder .component-preview-textarea select,
#root .nextgen-page-builder .component-preview-payment-card select,
#root .nextgen-page-builder .component-preview-table select {
  max-height: 40px;
}
#root .nextgen-page-builder .component-preview-input textarea,
#root .nextgen-page-builder .component-preview-select textarea,
#root .nextgen-page-builder .component-preview-textarea textarea,
#root .nextgen-page-builder .component-preview-payment-card textarea,
#root .nextgen-page-builder .component-preview-table textarea {
  font-family: var(--font-family);
  resize: none;
}
#root .nextgen-page-builder .component-preview-table table {
  border-collapse: collapse;
  vertical-align: top;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  border-spacing: 0;
  text-align: left;
  position: relative;
}
#root .nextgen-page-builder .component-preview-table td,
#root .nextgen-page-builder .component-preview-table th {
  border: 1px solid var(--color-border-table);
  padding: calc(var(--font-size) / 2);
}
#root .nextgen-page-builder .component-preview-table th {
  text-align: left;
}
#root .nextgen-page-builder .component-preview-file-upload .component-preview-file-upload-drop-zone {
  width: 100%;
  border: dashed 1px gray;
  padding: 1em;
  text-align: center;
}
#root .nextgen-page-builder .component-preview-image {
  max-width: 300px;
}
#root .nextgen-page-builder .page-component-preview-label {
  display: inline-block;
  margin-bottom: 5px;
}
#root .nextgen-page-builder .component-preview-checkbox .page-component-preview-label {
  display: inline;
  margin-left: 6px;
}
#root .nextgen-page-builder .component-preview-list .page-component-preview-label,
#root .nextgen-page-builder .component-preview-outcomes .page-component-preview-label,
#root .nextgen-page-builder .component-preview-radio .page-component-preview-label,
#root .nextgen-page-builder .component-preview-chart .page-component-preview-label {
  display: block;
  margin-bottom: 10px;
}
#root .nextgen-page-builder .component-preview-pdf,
#root .nextgen-page-builder .component-preview-outcomes {
  pointer-events: none;
  user-select: none;
}
#root .nextgen-page-builder .component-preview-payment-card-grid-container {
  display: grid;
  row-gap: 0.25rem;
  column-gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  width: max-content;
}
#root .nextgen-page-builder .component-preview-payment-card-card-number-container {
  grid-column: span 2;
}
#root .nextgen-page-builder .component-preview-payment-card-input-container {
  display: flex;
  flex-direction: column;
}
#root .nextgen-page-builder .component-preview-signature-pad-mock,
#root .nextgen-page-builder .component-preview-code-generator-mock,
#root .nextgen-page-builder .component-preview-code-scanner-mock {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: var(--exo-radius-standard);
  padding: 2rem;
}
#root .nextgen-page-builder .component-preview-tiles .preview-tiles {
  border: 1px solid var(--exo-palette-gray-30);
  height: 300px;
  width: 300px;
  padding: var(--exo-spacing-medium);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
#root .nextgen-page-builder .component-preview-tiles .preview-tiles h2 {
  margin-top: 0;
}
#root .nextgen-page-builder .component-preview-tiles .preview-tiles .body {
  height: 100%;
}
#root .nextgen-page-builder .component-preview-iframe-header {
  margin: 0 20px;
}
#root .nextgen-page-builder .component-preview-iframe-skeleton {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
}
#root .nextgen-page-builder .component-preview-iframe-skeleton span {
  display: block;
  height: 15px;
  border-radius: 500px;
  background-color: #f5f5f5;
}
#root .nextgen-page-builder .component-preview-ai-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#root .nextgen-page-builder .panel .btn + .btn,
#root .nextgen-page-builder .confirmation-modal .btn + .btn {
  margin-left: var(--exo-spacing-none);
}
#root .nextgen-page-builder .sidebar-right .form-group,
#root .nextgen-page-builder .sidebar-right .help-block {
  margin: var(--exo-spacing-none);
}
#root .nextgen-page-builder .help-block.error-state {
  margin: var(--exo-spacing-2x-small) var(--exo-spacing-none) var(--exo-spacing-none) var(--exo-spacing-none);
}
#root .nextgen-page-builder .form-textarea {
  resize: none;
}
#root .nextgen-page-builder .panel .picker,
#root .nextgen-page-builder .sidebar-section-content .picker {
  padding: var(--exo-spacing-x-small);
  background-color: var(--exo-palette-white);
  border-radius: var(--exo-radius-small);
}
#root .nextgen-page-builder .align-left {
  align-self: start;
}
#root .nextgen-page-builder {
  --sidebar-R-min-size: 30vw;
  --sidebar-R-max-size: 50vw;
  --custom-dark-blue: #daedf9;
  --custom-light-blue: #eff7fe;
  --custom-light-green: #e1f4f3;
  --c-hatch-dark: #daedf9;
  --c-hatch-light: #eff7fe;
  --hatch: repeating-linear-gradient(-45deg, var(--c-hatch-dark), var(--c-hatch-dark) 5px, var(--c-hatch-light) 5px, var(--c-hatch-light) 10px);
  --c-hatch-dark-alt: #cccccc;
  --c-hatch-light-alt: #dfdfdf;
  --hatch-alt: repeating-linear-gradient(45deg, var(--c-hatch-dark-alt), var(--c-hatch-dark-alt) 5px, var(--c-hatch-light-alt) 5px, var(--c-hatch-light-alt) 10px);
  width: 100%;
  height: 100%;
  display: none;
  position: relative;
}
#root .nextgen-page-builder.active {
  display: flex;
  flex-flow: column nowrap;
}
#root .nextgen-page-builder :not(.modal-dialog *)::-webkit-scrollbar {
  width: 10px;
}
#root .nextgen-page-builder ::-webkit-scrollbar-track {
  background: var(--exo-palette-white);
  border-radius: var(--exo-radius-standard);
}
#root .nextgen-page-builder ::-webkit-scrollbar-thumb {
  background: var(--exo-palette-gray-30);
  border: 4px solid var(--exo-palette-white);
  border-radius: var(--exo-radius-large);
}
#root .nextgen-page-builder ::-webkit-scrollbar-track:hover {
  background: var(--exo-palette-white);
}
#root .nextgen-page-builder ::-webkit-scrollbar-thumb:hover {
  background: var(--exo-palette-gray-50);
  border: 2px solid var(--exo-palette-white);
}
#root .nextgen-page-builder .btn.save-page-button {
  font-size: var(--exo-font-size-medium);
}
#root .nextgen-page-builder .runtime-marker {
  display: inline-block;
  justify-self: start;
  font-size: var(--exo-font-size-micro);
  line-height: 1;
  text-transform: capitalize;
  white-space: nowrap;
  border-radius: var(--exo-radius-x-large);
  padding: var(--exo-spacing-2x-small) var(--exo-spacing-x-small);
  color: var(--exo-palette-white);
  outline: 2px solid var(--exo-palette-white);
  outline-offset: -1px;
}
#root .nextgen-page-builder .runtime-marker:hover {
  cursor: default;
}
#root .nextgen-page-builder .runtime-marker.next {
  background-color: var(--exo-palette-blue-60);
}
#root .nextgen-page-builder .runtime-marker.legacy {
  background-color: var(--exo-palette-gray-60);
}
#root .nextgen-page-builder .runtime-marker.mini {
  width: 10px;
  height: 10px;
  padding: var(--exo-spacing-none);
}
#root .nextgen-page-builder .radio-button-group {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--exo-spacing-2x-small);
}
#root .nextgen-page-builder .radio-button-group.horizontal {
  flex-flow: row nowrap;
  gap: var(--exo-spacing-medium);
}
#root .nextgen-page-builder .radio-button-label,
#root .nextgen-page-builder .radio-button-input {
  cursor: pointer;
  margin: var(--exo-spacing-none);
  padding: var(--exo-spacing-none);
}
#root .nextgen-page-builder .radio-button-label {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: var(--exo-spacing-x-small);
  font: var(--exo-text-label-standard);
}
#root .nextgen-page-builder .radio-button-input {
  transform: scale(1.25);
}
