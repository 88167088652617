.activation-select-player {
  margin-top: 10px;
}
.activation-successful h3 {
  margin-top: 0;
}
.activation-successful table.published-local {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  border-collapse: separate;
  border-spacing: 0 2px;
}
.activation-successful .published-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
}
.activation-successful .published-wrapper .published-item {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 10px;
}
.activation-successful .published-wrapper .published-item .config-label {
  padding-right: 10px;
  width: 80px;
}
.activation-successful table .config-label {
  text-align: right;
  padding-right: 10px;
}
.activation-successful table pre {
  margin: 0;
  padding: 4px;
}
