#root .difference {
  color: red;
}
#root .increase {
  color: green;
}
#root .total-events {
  font-size: 2em;
  margin-right: 0.3em;
}
