#root .graph-canvas .group-element-icon {
  position: absolute;
  top: 0px;
  cursor: pointer;
  font-size: 10px;
  background: white;
}
#root .graph-canvas .group-element-icon.group-element-delete {
  font-size: 9px;
  color: #de6854;
  right: 0px;
  border-radius: 50%;
}
#root .graph-canvas .group-element-icon.group-element-unlink,
#root .graph-canvas .group-element-icon.group-element-link {
  right: 15px;
  height: 9px;
  border-radius: 0.2px;
}
#root .graph-canvas .group-element-icon.group-element-arrange {
  right: 30px;
  height: 9px;
  border-radius: 10px;
}
#root .graph-canvas .drag .resize-handle {
  display: none;
}
#root .graph-canvas .group-element-drag-handle {
  cursor: move;
}
#root .graph-canvas .group-element-hover-icon {
  cursor: pointer;
}
#root .graph-canvas .group-element-content {
  color: #fff;
  text-align: center;
}
#root .graph-canvas .group-element-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  justify-content: center;
}
#root .graph-canvas .group-element-text:not(.editing) {
  overflow: hidden;
}
#root .graph-canvas .select foreignObject .map-element-content .group-element-text {
  cursor: text;
}
#root .graph-canvas .select foreignObject .map-element-content .group-element-text .map-element-developer-name[contenteditable='false']:hover {
  background: rgba(255, 255, 255, 0.411);
}
#root .graph-canvas .select foreignObject .map-element-content .group-element-text .map-element-developer-name[contenteditable='true']:focus {
  background: rgba(255, 255, 255, 0.8);
}
