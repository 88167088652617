.business-rule {
  display: grid;
  grid-template-columns: 1fr 210px 1fr auto;
  gap: var(--exo-spacing-small);
  margin-bottom: var(--exo-spacing-standard);
  align-items: center;
}
.business-rule .left-value-selector {
  grid-column: 1 / 2;
}
.business-rule .criteria-select {
  grid-column: 2 / 3;
}
.business-rule .right-value-selector {
  grid-column: 3 / 4;
}
.business-rule .btn-icon.danger {
  grid-column: 4 / 5;
}
.comparison-group {
  padding-left: 10px;
  border-left: 1px dashed var(--exo-palette-gray-40);
}
.comparison-group > .btn-group {
  margin-bottom: var(--exo-spacing-standard);
}
.comparison-group .btn-group {
  display: inline-block;
}
