#root .value-id {
  font-size: 12px;
  margin-bottom: 15px;
}
#root .value-editor-container .table-wrapper td {
  border-top: 0 none;
}
#root .value-editor-container .btn .glyphicon:last-child,
#root .value-editor-container .caret {
  margin-left: 0;
}
#root .value-editor-container td {
  position: relative;
}
#root .value-editor-container .add-row .btn,
#root .value-editor-container .new-value .btn,
#root .value-editor-container .edit-child-value .btn,
#root .value-editor-container .new-child-value .btn,
#root .value-editor-container .row-delete .btn {
  padding: 0 16px;
  width: auto;
  height: 37px;
  font-size: 14px;
}
#root .value-editor-container .panel-heading {
  cursor: pointer;
}
#root .value-editor-container .panel-info {
  border: 0 none;
}
#root .value-editor-container .panel-info > .panel-heading {
  color: inherit;
  background-color: var(--exo-palette-gray-10);
  border-color: var(--exo-palette-gray-20);
}
#root .value-editor-container .panel-info > .panel-heading + .panel-collapse > .panel-body {
  border: 0 none;
  padding: 0;
}
#root .value-editor-container .panel-info > .panel-heading + .panel-collapse > .panel-body .table {
  margin-bottom: 0;
}
#root .value-editor-container .accordion-toggle:before {
  /* glyphicon-chevron-up symbol for "opening" panels */
  font-family: 'Glyphicons Halflings';
  content: '\e113';
  padding-right: 5px;
}
#root .value-editor-container .panel-heading.collapsed .accordion-toggle:before {
  /* glyphicon-chevron-down symbol for "collapsed" panels */
  content: '\e114';
}
#root .value-editor-container .bootstrap-datetimepicker-widget .accordion-toggle:before {
  display: none;
}
#root .value-editor-container .bootstrap-datetimepicker-widget .prev,
#root .value-editor-container .bootstrap-datetimepicker-widget .picker-switch,
#root .value-editor-container .bootstrap-datetimepicker-widget .next,
#root .value-editor-container .bootstrap-datetimepicker-widget .dow {
  background: none;
}
#root .value-editor-container .panel-title {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}
#root .value-editor-container .panel-title a {
  display: block;
}
#root .value-editor-container textarea {
  resize: vertical;
}
#root .value-editor-container .add-list-item-wrapper {
  margin-right: 1em;
}
.value-detail .value-editor > *:not(.object-data-editor, .code-type-editor) {
  max-width: 600px;
}
.value-editor ex-input,
.value-editor ex-select {
  margin-bottom: 1em;
}
.value-editor-modal .modal-footer {
  display: flex;
  justify-content: end;
  gap: var(--exo-spacing-small);
}
#root .value-editor-type-selector label {
  font: var(--exo-text-label-micro);
}
#root .value-editor-type-selector .input-required {
  font: var(--exo-text-label-micro);
  color: var(--exo-color-font-secondary);
}
.object-data-editor {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 50% 50%;
  gap: var(--exo-spacing-standard);
  margin-bottom: var(--exo-spacing-medium);
}
.object-data-editor .object-data-editor-filter {
  max-width: 600px;
}
.object-data-editor .object-data-editor-tree {
  display: flex;
  flex-direction: column;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  border-right: 1px solid var(--exo-palette-gray-20);
  padding-right: var(--exo-spacing-standard);
  position: relative;
}
.object-data-editor .object-data-editor-value {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}
.object-data-editor .object-data-editor-value > * {
  max-width: 600px;
}
.object-data-editor ol {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  font: var(--exo-doc-body-small);
  color: var(--exo-color-font);
}
.object-data-editor li ol {
  border-left: 1px solid var(--exo-palette-gray-20);
  padding-left: calc(var(--exo-font-size-x-small) * 2);
  margin-bottom: var(--exo-spacing-medium);
}
.object-data-editor .object-data-editor-item-header {
  display: flex;
  gap: var(--exo-spacing-small);
  align-items: center;
}
.object-data-editor .object-data-editor-item-header > span {
  padding: var(--exo-font-size-x-small);
  padding-left: 0;
}
.object-data-editor ol li > button {
  padding: var(--exo-font-size-x-small);
  padding-left: 0;
  background: transparent;
  border: none;
  text-align: left;
  display: flex;
  overflow: hidden;
}
.object-data-editor ol li > button > * {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 50%;
  overflow: hidden;
}
.object-data-editor ol li > button > .property-name > * {
  display: block;
}
.object-data-editor ol li > button .complex-property-type-name {
  font-size: var(--exo-font-size-x-small);
  color: var(--exo-palette-gray-60);
}
.object-data-editor ol li > button > span:nth-child(2) {
  text-align: right;
}
.object-data-editor ol li > button:not(.complex-property).selected,
.object-data-editor ol li > button:not(.complex-property):hover {
  background-color: var(--exo-color-background-action-secondary-hover);
  border-color: var(--exo-color-background-action-secondary-hover);
}
.object-data-editor-item {
  display: flex;
  flex-direction: column;
}
.object-data-editor-item.hover {
  background-color: var(--exo-palette-aqua-10);
}
.object-data-editor-item.hover ol {
  border-left: 1px solid var(--exo-palette-gray-30);
}
.object-data-editor-add-new-item {
  align-self: flex-start;
}
li .object-data-editor-add-new-item {
  margin-bottom: var(--exo-spacing-medium);
}
