.api-tool {
    display: flex;
    flex-direction: column;
    padding: 8px;
    width: 100%;
    height: 100%;

    #request-editor,
    #response-editor {
        border-radius: var(--exo-radius-standard);
    }

    .api-endpoint {
        display: flex;
        position: relative;
    }

    .request-response-container {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 10px;

        .editor-heading {
            margin: 10px 0;
        }

        .request,
        .response {
            display: flex;
            flex-direction: column;
        }
    }
}
