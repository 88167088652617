@keyframes dropIn {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#root .modal-backdrop {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  height: 100%;
  background-color: var(--exo-color-scrim);
  animation: fadeIn 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
#root .modal-dialog {
  max-width: 75%;
  min-width: 50%;
  border: 0;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.3s, dropIn 0.3s ease-out;
  width: 600px;
}
#root .modal-dialog.modal-dialog-large {
  width: 80%;
}
#root .modal-dialog.modal-dialog-small {
  width: auto;
  min-width: 300px;
  max-width: 600px;
}
#root .modal-header {
  min-height: 16.42857143px;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
#root .modal-header .close {
  margin-top: -2px;
  appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}
#root .modal-body {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 200px;
  position: relative;
  padding: 15px;
  max-height: calc(100vh - 365px);
  overflow-y: auto;
  overflow-x: hidden;
}
#root .modal-body.no-padding {
  padding: 0;
}
#root .modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
#root .buttons-right {
  display: flex;
  justify-content: end;
  gap: var(--exo-spacing-small);
}
#root .modal-title {
  margin: 0;
}
#root .confirm-modal .modal-body {
  min-height: 50px;
}
#root .confirm-modal .modal-body .message-line {
  padding-bottom: 1em;
}
#root .confirm-modal .modal-footer {
  background: whitesmoke;
  border-radius: 0 0 6px 6px;
  display: flex;
  justify-content: end;
}
#root .modal-body.auto-height {
  min-height: auto;
}
