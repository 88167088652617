.component-suggestion-list {
  display: flex;
}
#root .component-suggestion .nextgen-page-builder .composer {
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
}
#root .component-suggestion .nextgen-page-builder.composer {
  overflow: visible;
}
#root .component-suggestion-modal {
  width: fit-content;
  min-width: auto;
}
#root .component-suggestion-modal .modal-body {
  overflow: auto;
}
#root .component-suggestion-modal .suggest-info {
  font-size: var(--exo-font-size-micro);
  color: var(--exo-palette-gray-50);
}
#root .component-suggestion-modal .suggest-tiles-preview {
  display: flex;
}
#root .component-suggestion-modal .suggest-tiles-preview .left-section {
  width: 100%;
  margin-right: var(--exo-spacing-x-small);
  display: flex;
  flex-direction: column;
}
#root .component-suggestion-modal .suggest-tiles-preview .left-section table {
  margin-top: var(--exo-spacing-small);
}
#root .component-suggestion-modal .suggest-tiles-preview .component-preview-tiles {
  margin-left: var(--exo-spacing-x-small);
}
#root .component-suggestion-modal .suggest-columns-preview {
  display: flex;
}
#root .component-suggestion-modal .suggest-columns-preview .left-section {
  width: 100%;
  margin-right: var(--exo-spacing-x-small);
  display: flex;
  flex-direction: column;
}
#root .component-suggestion-modal .suggest-columns-preview .left-section .search-control-wrapper {
  width: auto;
  margin-bottom: var(--exo-spacing-x-small);
  outline: 0;
}
#root .component-suggestion-modal .suggest-columns-preview .left-section .column-label {
  font-size: var(--exo-font-size-micro);
  margin-bottom: 5px;
}
#root .component-suggestion-modal .suggest-columns-preview .left-section .select-all-deselect-all {
  display: flex;
  align-items: center;
  padding: 0 var(--exo-spacing-x-small) var(--exo-spacing-x-small) var(--exo-spacing-x-small);
}
#root .component-suggestion-modal .suggest-columns-preview .left-section .select-all-deselect-all input {
  width: 16px;
  height: 16px;
  margin: 0;
}
#root .component-suggestion-modal .suggest-columns-preview .left-section .select-all-deselect-all label {
  margin: 0 0 0 var(--exo-spacing-x-small);
  cursor: pointer;
}
#root .component-suggestion-modal .suggest-columns-preview .left-section .suggest-columns {
  overflow: hidden auto;
  max-height: 500px;
}
#root .component-suggestion-modal .suggest-columns-preview .left-section .suggest-columns .suggest-column {
  display: flex;
  border: 1px solid #e5e5e5;
}
#root .component-suggestion-modal .suggest-columns-preview .left-section .suggest-columns .suggest-column:not(:first-of-type) {
  border-top: 0;
}
#root .component-suggestion-modal .suggest-columns-preview .left-section .suggest-columns .suggest-column:first-of-type {
  border-top-right-radius: var(--exo-radius-standard);
  border-top-left-radius: var(--exo-radius-standard);
}
#root .component-suggestion-modal .suggest-columns-preview .left-section .suggest-columns .suggest-column:last-of-type {
  border-bottom-right-radius: var(--exo-radius-standard);
  border-bottom-left-radius: var(--exo-radius-standard);
}
#root .component-suggestion-modal .suggest-columns-preview .left-section .suggest-columns .suggest-column input[type='checkbox'] {
  min-width: 16px;
  width: 16px;
  height: 16px;
  margin: auto 0 auto var(--exo-spacing-x-small);
}
#root .component-suggestion-modal .suggest-columns-preview .left-section .suggest-columns .suggest-column label {
  display: flex;
  justify-content: space-between;
  padding: var(--exo-spacing-x-small);
  margin: 0;
  cursor: pointer;
  flex-grow: 1;
  overflow: hidden;
}
#root .component-suggestion-modal .suggest-columns-preview .left-section .suggest-columns .suggest-column label .column-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#root .component-suggestion-modal .suggest-columns-preview .left-section .suggest-columns .suggest-column label .suggest-info {
  margin-left: var(--exo-spacing-x-small);
}
#root .component-suggestion-modal .suggest-columns-preview .suggest-preview {
  margin-left: var(--exo-spacing-x-small);
  overflow: hidden;
}
#root .component-suggestion-modal .suggest-columns-preview .suggest-preview #column-select {
  margin-bottom: 15px;
}
#root .component-suggestion-modal .suggest-columns-preview .suggest-preview .component-preview-table {
  pointer-events: initial;
}
#root .component-suggestion-modal .suggest-columns-preview .suggest-preview .component-preview-table .page-component-preview-label {
  font-size: var(--exo-font-size-micro);
}
#root .component-suggestion-modal .suggest-columns-preview .suggest-preview .component-preview-table table {
  table-layout: fixed;
}
#root .component-suggestion-modal .suggest-columns-preview .suggest-preview .component-preview-table table th {
  overflow: hidden;
  text-overflow: ellipsis;
}
#root .component-suggestion-modal .value-selector-values-option {
  display: block;
}
.component-suggestion {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  flex: auto;
  min-width: 325px;
  padding: 0;
  text-align: initial;
  background: none;
  border: none;
}
.component-suggestion:not(:first-child) {
  margin-left: var(--exo-spacing-x-small);
}
.component-suggestion:not(:last-child) {
  margin-right: var(--exo-spacing-x-small);
}
.component-suggestion .render {
  border: 2px solid var(--exo-palette-gray-20);
  border-radius: var(--exo-radius-large);
  padding: var(--exo-spacing-x-small);
  height: calc(100% - var(--exo-line-height-body));
  overflow: hidden;
  position: relative;
  width: 100%;
}
.component-suggestion .render.loading {
  background: var(--exo-palette-gray-20);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.component-suggestion .render .component-preview-richtext {
  width: 305px;
}
.component-suggestion .text {
  padding-top: var(--exo-spacing-x-small);
  text-align: center;
  width: 100%;
}
.component-suggestion .check {
  color: var(--exo-palette-green-50);
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.component-suggestion .runtime-marker {
  position: absolute;
  top: 5px;
  right: 5px;
}
.component-suggestion:hover .render {
  border-color: var(--exo-palette-blue-30);
}
