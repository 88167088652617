.audit-filters {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1em;
  max-width: 1302px;
}
.audit-filters .filter-column {
  position: relative;
}
.audit-filters .simple-value {
  display: grid !important;
  grid-template-columns: 1fr auto;
}
.audit-filters .delete-item {
  border: none;
  border-left: 1px solid #c9e6f2;
  padding: 0 5px;
  background: transparent;
}
.audit-filters .delete-item:focus {
  outline: none;
}
.event-entry-response {
  height: 400px;
}
