.collaboration-users {
    position: absolute;
    top: 13px;
    display: flex;
    flex-direction: row-reverse;
}

.flow-editor .collaboration-users {
    right: calc(2rem + 295px);
}

.modal-header .collaboration-users {
    right: calc(1rem + 65px);
}

.collaboration-user {
    width: 42px;
    height: 42px;
    border: 1px solid #f5f5f5;
    border-radius: 50%;
    color: white;
    text-align: center;
    line-height: 42px;
    transition: transform 0.2s ease;
    transform-origin: 24px 24px;
    cursor: pointer;
    font-size: 16px;
}

.collaboration-user-1 {
    transform: translateX(0px);
    background-color: rgb(103, 171, 225);
}

.collaboration-user-1:hover {
    transform: scale(1.2);
}

.collaboration-user-2 {
    transform: translateX(-8px);
    background-color: rgb(14, 160, 118);
}

.collaboration-user-2:hover {
    transform: translateX(-8px) scale(1.2);
}

.collaboration-user-3 {
    transform: translateX(-16px);
    background-color: rgb(250, 75, 109);
}

.collaboration-user-3:hover {
    transform: translateX(-16px) scale(1.2);
}

.collaboration-user-4 {
    transform: translateX(-24px);
    background-color: rgb(239, 160, 55);
}

.collaboration-user-4:hover {
    transform: translateX(-24px) scale(1.2);
}

.collaboration-user-5 {
    transform: translateX(-32px);
    background-color: rgb(128, 128, 128);
}

.collaboration-user-5:hover {
    transform: translateX(-32px) scale(1.2);
}

.collaboration-user-more {
    transform: translateX(-36px);
    background: lightgray;
    color: black;
}

.collaboration-user:last-child {
    margin-right: 0;
}
