.graph-canvas-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
.graph-canvas-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  display: grid;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
}
.graph-canvas-overlay-left {
  overflow: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.graph-canvas-overlay-left * {
  pointer-events: initial;
}
.graph-canvas-overlay-right {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.graph-canvas-overlay-right * {
  pointer-events: initial;
}
.graph-canvas {
  user-select: none;
  flex: 1;
}
.graph-canvas:focus {
  outline: none;
}
.graph-keyboard-focusable:focus {
  border: 2px solid black;
}
.graph-keyboard-focusable:focus:not(:focus-visible) {
  outline: none;
  border: none;
}
.graph-moving:focus {
  border: 2px dashed black;
  outline: none;
}
.graph-canvas.searching [id^='background-dots-'],
.graph-canvas.searching .group-element-link {
  filter: opacity(0.3);
}
.out-of-search {
  filter: opacity(0.3);
}
.sidebar-element.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.help-menu {
  position: absolute;
  list-style: none;
  right: 15px;
  bottom: 60px;
  background: white;
  border-radius: 13px;
  border: 1px solid var(--exo-palette-brand);
  padding: 10px;
  margin-bottom: 0;
}
.help-menu li {
  margin: 5px;
}
.settings-menu {
  position: absolute;
  right: 15px;
  bottom: 60px;
  background: white;
  border-radius: 13px;
  border: 1px solid var(--exo-palette-brand);
  margin-bottom: 0;
  width: 350px;
}
.settings-menu .settings-body {
  padding: 10px;
}
.settings-menu .settings-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding: 15px;
  background-color: var(--exo-palette-gray-10);
  border-radius: 13px;
  border-top: 1px solid #ccc;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.settings-menu .settings-heading {
  text-align: center;
}
.settings-menu .thickness-slider {
  appearance: none;
  width: 100%;
  height: 15px;
  background: var(--exo-palette-gray-20);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}
.settings-menu .thickness-slider:disabled {
  background: var(--exo-palette-gray-10);
}
.settings-menu .thickness-slider:disabled::-webkit-slider-thumb {
  background: var(--exo-palette-navy-30);
  cursor: default;
}
.settings-menu .thickness-slider:disabled::-moz-range-thumb {
  background: var(--exo-palette-navy-30);
  cursor: default;
}
.settings-menu .thickness-slider:disabled:hover {
  opacity: 0.7;
}
.settings-menu .thickness-slider:hover {
  opacity: 1;
}
.settings-menu .thickness-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--exo-palette-brand);
  cursor: pointer;
  border-radius: 50%;
}
.settings-menu .thickness-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--exo-palette-brand);
  cursor: pointer;
  border-radius: 50%;
}
#cytoscape-container {
  width: 100%;
  height: 100%;
  display: none;
}
#root .graph-actions-menu {
  width: min-content;
  height: min-content;
  align-self: flex-end;
  margin-top: auto;
}
#root .graph-actions-menu .graph-action {
  display: flex;
  align-items: center;
  min-width: 0;
  font-size: 1.3em;
  border-right: 1px solid #ddd;
  padding: 6px 8px;
}
#root .graph-actions-menu .graph-action:first-child {
  border-top-left-radius: var(--exo-radius-standard);
  border-bottom-left-radius: var(--exo-radius-standard);
}
#root .graph-actions-menu .graph-action:last-child {
  border-top-right-radius: var(--exo-radius-standard);
  border-bottom-right-radius: var(--exo-radius-standard);
  border: 0;
}
#root .graph-actions-menu .graph-action.dark {
  background-color: var(--exo-palette-brand);
  color: white;
}
#root .graph-actions-menu .graph-action svg {
  overflow: visible;
}
#root .canvas-palette {
  overflow-y: auto;
  padding: 0.6em 0;
  align-self: flex-start;
}
#root .canvas-palette .sidebar-button-wrapper,
#root .canvas-palette .modal-button-wrapper {
  display: flex;
}
#root .canvas-palette .sidebar-button-wrapper .sidebar-btn,
#root .canvas-palette .modal-button-wrapper .sidebar-btn,
#root .canvas-palette .sidebar-button-wrapper .modal-btn,
#root .canvas-palette .modal-button-wrapper .modal-btn {
  border: 0;
  background: none;
  color: black;
  cursor: move;
  display: flex;
  align-items: center;
  padding: 0.3em 1.2em;
  box-sizing: border-box;
}
#root .canvas-palette .sidebar-button-wrapper .sidebar-btn:hover:not(.heading),
#root .canvas-palette .modal-button-wrapper .sidebar-btn:hover:not(.heading),
#root .canvas-palette .sidebar-button-wrapper .modal-btn:hover:not(.heading),
#root .canvas-palette .modal-button-wrapper .modal-btn:hover:not(.heading) {
  color: black;
}
#root .canvas-palette .sidebar-button-wrapper .sidebar-btn:hover:not(.heading) .glyphicon,
#root .canvas-palette .modal-button-wrapper .sidebar-btn:hover:not(.heading) .glyphicon,
#root .canvas-palette .sidebar-button-wrapper .modal-btn:hover:not(.heading) .glyphicon,
#root .canvas-palette .modal-button-wrapper .modal-btn:hover:not(.heading) .glyphicon {
  color: var(--exo-palette-white);
  background: none;
}
#root .canvas-palette .sidebar-button-wrapper .sidebar-btn .sidebar-btn-icon,
#root .canvas-palette .modal-button-wrapper .sidebar-btn .sidebar-btn-icon,
#root .canvas-palette .sidebar-button-wrapper .modal-btn .sidebar-btn-icon,
#root .canvas-palette .modal-button-wrapper .modal-btn .sidebar-btn-icon,
#root .canvas-palette .sidebar-button-wrapper .sidebar-btn .modal-btn-icon,
#root .canvas-palette .modal-button-wrapper .sidebar-btn .modal-btn-icon,
#root .canvas-palette .sidebar-button-wrapper .modal-btn .modal-btn-icon,
#root .canvas-palette .modal-button-wrapper .modal-btn .modal-btn-icon {
  border-radius: 100%;
  padding: 3px;
}
#root .canvas-palette .sidebar-button-wrapper .sidebar-btn .sidebar-item-text,
#root .canvas-palette .modal-button-wrapper .sidebar-btn .sidebar-item-text,
#root .canvas-palette .sidebar-button-wrapper .modal-btn .sidebar-item-text,
#root .canvas-palette .modal-button-wrapper .modal-btn .sidebar-item-text,
#root .canvas-palette .sidebar-button-wrapper .sidebar-btn .modal-item-text,
#root .canvas-palette .modal-button-wrapper .sidebar-btn .modal-item-text,
#root .canvas-palette .sidebar-button-wrapper .modal-btn .modal-item-text,
#root .canvas-palette .modal-button-wrapper .modal-btn .modal-item-text {
  display: none;
}
#root .canvas-palette .sidebar-button-wrapper .sidebar-btn .glyphicon,
#root .canvas-palette .modal-button-wrapper .sidebar-btn .glyphicon,
#root .canvas-palette .sidebar-button-wrapper .modal-btn .glyphicon,
#root .canvas-palette .modal-button-wrapper .modal-btn .glyphicon {
  display: flex;
  top: 0px;
  padding: 5px;
  border-radius: 100%;
  background: var(--exo-palette-white);
}
#root .flow-action-menu {
  width: min-content;
  height: min-content;
  align-self: flex-end;
}
#root .flow-action-menu .flow-action-wrapper {
  display: flex;
  border-left: 1px solid #ddd;
}
#root .flow-action-menu .flow-action-wrapper:first-child {
  border: 0;
}
#root .flow-action-menu .flow-action-wrapper .flow-action {
  display: flex;
  align-items: center;
  min-width: 0;
  font-size: 1.3em;
  padding: 8px 12px;
}
#root .flow-action-menu .flow-action-wrapper .flow-action.dark {
  background-color: var(--exo-palette-brand);
  color: white;
}
#root .flow-action-menu .flow-action-wrapper .flow-action svg {
  overflow: visible;
}
#root .graph-actions-menu .search-input {
  border: 0;
  margin-left: 5px;
  padding: 0 5px;
}
#root .graph-tooltip {
  color: black;
  font-family: var(--default-font-family);
  min-width: 400px;
  user-select: none;
  pointer-events: none;
}
#root .graph-tooltip .graph-tooltip-arrow {
  position: absolute;
  background: white;
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.grabbing {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.grabbing .glyphicon {
  margin-top: 20px;
  margin-left: 15px;
}
.grabbing span {
  font-family: var(--default-font-family);
  font-size: 14px;
  color: white;
  margin-top: 20px;
  margin-left: 5px;
}
.swimlane-parent {
  border: 1px solid #f49756;
  border-left: 31px solid #f49756;
  background: transparent;
  position: relative;
}
.swimlane-parent:after {
  position: absolute;
  left: -60px;
  top: 65px;
  color: white;
  font-weight: 500;
  font-family: var(--default-font-family);
  font-size: 14px;
  -ms-transform: rotate(-90deg);
  /* IE 9 */
  -webkit-transform: rotate(-90deg);
  /* Safari */
  transform: rotate(-90deg);
  content: 'New Element';
}
.group-parent {
  border: 1px solid #c191e6;
  border-top: 31px solid #c191e6;
  border-radius: 10px;
  background: transparent;
  position: relative;
}
.group-parent:after {
  position: absolute;
  left: 210px;
  top: -23px;
  color: white;
  font-weight: 500;
  font-family: var(--default-font-family);
  font-size: 14px;
  content: 'New Element';
}
#root .flow-editor .list-unstyled {
  overflow-x: unset;
}
#root .flow-editor .map-element-config-modal .modal-body {
  max-height: calc(100vh - 365px);
  overflow: auto;
}
#root .flow-editor .map-element-config-modal .modal-body.picker-modal-body {
  max-height: calc(100vh - 373px);
}
#root .canvas-blocked {
  cursor: not-allowed;
}
.shortcut {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 2px 6px;
  font-size: 0.8em;
  display: inline-block;
  min-width: 1em;
  margin: 0;
  cursor: default;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  text-shadow: 0 0 2px #ffffff;
  -moz-box-shadow: inset 0 0 1px #ffffff, inset 0 0 0.4em #c8c8c8, 0 0.1em 0 #828282, 0 0.11em 0 rgba(0, 0, 0, 0.4), 0 0.1em 0.11em rgba(0, 0, 0, 0.9);
  -webkit-box-shadow: inset 0 0 1px #ffffff, inset 0 0 0.4em #c8c8c8, 0 0.1em 0 #828282, 0 0.11em 0 rgba(0, 0, 0, 0.4), 0 0.1em 0.11em rgba(0, 0, 0, 0.9);
  box-shadow: inset 0 0 1px #ffffff, inset 0 0 0.4em #c8c8c8, 0 0.1em 0 #828282, 0 0.11em 0 rgba(0, 0, 0, 0.4), 0 0.1em 0.11em rgba(0, 0, 0, 0.9);
}
.graph-canvas .safari-fix {
  position: static;
}
