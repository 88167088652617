.inline-input-wrapper {
  position: relative;
  overflow: visible;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
}
.inline-input-static {
  width: 100%;
}
.inline-input-button {
  font-size: 1.3rem;
}
.inline-input-button-editing {
  margin-right: 5px;
  padding: 5px 8px;
  font-size: 1.3rem;
  border: 1px solid var(--exo-palette-gray-30);
  border-radius: var(--exo-radius-standard);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: var(--exo-palette-gray-10);
  color: var(--exo-palette-gray-70);
}
.inline-input-button-editing:hover {
  background-color: var(--exo-palette-gray-20);
}
.inline-input {
  position: absolute;
  top: -18px;
  right: 0;
  z-index: 1;
  width: 100%;
}
.inline-textarea {
  resize: vertical;
}
.inline-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}
