#root .search-control-wrapper {
  display: flex;
  align-items: center;
  background-color: var(--exo-palette-white);
  border-radius: var(--exo-radius-standard);
  width: 18rem;
  border: 1px solid var(--exo-palette-gray-30);
  height: var(--exo-spacing-x-large);
}
#root .search-control-wrapper .search-icon {
  font-size: 1.3rem;
  margin: 0 0.6rem;
  color: var(--exo-palette-gray-60);
}
#root .search-control-wrapper .search-input {
  padding: 0.4rem 0.8em;
  flex-grow: 2;
  border: none;
  background: transparent;
}
#root .search-control-wrapper .search-input:focus {
  outline: none;
}
#root .search-control-wrapper .search-input-button {
  height: 100%;
  min-height: 100%;
  border: 0;
}
#root .search-control-wrapper .clear-icon {
  font-size: 1rem;
  margin: 0.2rem 0.2rem 0 0;
  color: var(--exo-palette-gray-60);
}
#root .search-control-wrapper:focus-within {
  outline: 2px solid var(--exo-palette-black);
}
