.icon-picker__option:hover {
  background: #deebff;
}
#root .asset-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 560px;
}
#root .asset-button {
  padding: 0 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
#root .preview-panel {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 20px;
}
#root .preview-panel .nextgen-page-builder {
  width: auto;
  margin: 20px;
}
.icon-option {
  display: flex;
  padding: 10px 20px;
}
.icon-option svg {
  font-size: 23px;
}
.icon-option span {
  margin-left: 5px;
  line-height: 26px;
  vertical-align: top;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icon-value svg {
  font-size: 23px;
}
.icon-value span {
  margin-left: 5px;
  line-height: 26px;
  vertical-align: top;
}
.components .warning-icon {
  margin-left: 5px;
  vertical-align: bottom;
}
.components .table .help-block {
  margin-bottom: 0;
}
#root .components .generic-table > tbody > tr > td.cell-input {
  vertical-align: top;
  padding: 10px;
}
