.type-property-picker {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.type-property-picker-select-all {
  margin-bottom: var(--exo-spacing-x-small);
}
.type-property-picker-properties {
  overflow: hidden auto;
}
.type-property-picker-property {
  display: flex;
  border: 1px solid #e5e5e5;
  padding: var(--exo-spacing-x-small);
  gap: var(--exo-spacing-x-small);
}
.type-property-picker-property:first-of-type {
  border-top-right-radius: var(--exo-radius-standard);
  border-top-left-radius: var(--exo-radius-standard);
}
.type-property-picker-property:last-of-type {
  border-bottom-right-radius: var(--exo-radius-standard);
  border-bottom-left-radius: var(--exo-radius-standard);
}
#root .type-property-picker-property input[type='checkbox'] {
  margin: 0;
}
#root .type-property-picker-property > label {
  display: flex;
  flex-grow: 1;
  margin: 0;
}
.type-property-picker-property-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
}
.type-property-picker-property-content-type {
  font-size: var(--exo-font-size-micro);
  color: var(--exo-palette-gray-50);
  margin-left: var(--exo-spacing-x-small);
}
