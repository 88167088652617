#root .tenant-name-input-addons-container {
  display: flex;
  align-items: center;
}
#root .tenant-name-input-addon {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: var(--exo-spacing-x-large);
  font-size: 14px;
  color: var(--exo-palette-gray-80);
  text-align: center;
  background-color: var(--exo-palette-gray-10);
  border: 1px solid #ccc;
}
#root .tenant-name-input-field:only-child {
  border-radius: var(--exo-radius-standard);
}
#root .tenant-name-input-field:nth-last-child(2) {
  border-radius: var(--exo-radius-standard) 0 0 var(--exo-radius-standard);
}
#root .subtenant-name-suffix {
  min-width: 25%;
  border-radius: 0 var(--exo-radius-standard) var(--exo-radius-standard) 0;
  border-left: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
