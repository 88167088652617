.nav-tabs .nav-tab {
  display: block;
  padding: 10px 15px;
  margin-right: 2px;
  line-height: 1.43;
  border: 1px solid transparent;
  border-radius: var(--exo-radius-standard) var(--exo-radius-standard) 0 0;
  color: #337ab7;
  cursor: pointer;
  background-color: transparent;
}
.nav-tabs .nav-tab:hover {
  background-color: #eee;
}
.nav-tabs > .active > .nav-tab {
  color: #555;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}
.tab-content-header {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}
.tab-content-header .tab-header-search {
  display: flex;
  width: 50%;
  min-width: 300px;
}
.import-content-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.import-content-header .tab-header-search {
  display: flex;
  width: 50%;
  min-width: 300px;
}
