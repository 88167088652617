#root .graph-editor {
  /* hover-out-border displays on hover or select */
  /* ports displays on hover */
  /* hover-out-border has a stroke colour only when selected */
  /* remove keyboard focus outline */
  /* do not show ports while dragging */
}
#root .graph-editor .map-element .hover-out-border,
#root .graph-editor .map-element .map-element-ports {
  display: none;
}
#root .graph-editor .map-element:hover .hover-out-border,
#root .graph-editor .map-element:hover .map-element-ports,
#root .graph-editor .hover-out-border.map-element-selected {
  display: block;
}
#root .graph-editor .map-element:hover .hover-out-border {
  stroke: none;
}
#root .graph-editor .map-element .hover-out-border.map-element-selected {
  stroke: var(--exo-palette-gray-50);
}
#root .graph-editor .map-element:focus {
  outline: none;
}
#root .graph-editor .outcome[data-id='new'] {
  pointer-events: none;
}
#root .graph-editor .is-dragging .map-element:hover .map-element-ports {
  display: none;
}
