.user-tenant-id {
  color: #212121;
  margin-top: 0.5em;
  margin-bottom: 0;
  font-weight: 400;
  padding-right: 0.1em;
}
.user-dropdown-text {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: initial;
}
