.flow-properties-page .region-restriction {
  max-width: 500px;
  margin-right: 30px;
}
.flow-properties-page .region-restriction .add-restriction {
  margin-left: 8px;
}
.flow-properties-page .region-restriction .region-restriction-list {
  margin-left: 20px;
}
.flow-properties-page .button-height-container {
  height: 53px;
}
.flow-properties-page .button-padding-left-container {
  padding-left: 12px;
}
.flow-properties-page .glyphicon-margin-right {
  margin-right: 0.5em;
}
.flow-properties-page .outcome-margin-right {
  margin-right: 1em;
}
.flow-properties-page .mw-items-header {
  margin-bottom: 1em;
  overflow: hidden;
}
.flow-properties-page .mw-items-header > button {
  float: right;
  margin-bottom: 0;
  margin-left: 1em;
}
.flow-properties-page .wait-container-wrapper {
  position: relative;
  height: 300px;
}
.flow-properties-page .smaller-form-control {
  display: block;
  width: auto;
  height: var(--exo-spacing-x-large);
  padding: 8px 12px;
  font-size: 15px;
  line-height: 1.4;
  color: #6f6f6f;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.flow-properties-page .form-select {
  font-size: 13px;
  width: auto;
  padding: 4px 8px;
  height: 32px;
  min-width: 200px;
}
.flow-properties-page .collapsible-error-panel {
  border: 1px solid var(--exo-palette-red-20);
}
.flow-properties-page .collapsible-error-panel .panel-heading {
  padding: 5px 10px;
  border: none;
  background-color: var(--exo-palette-red-20);
}
.flow-properties-page .collapsible-error-panel .panel-title {
  color: var(--exo-palette-gray-90);
  font-size: 14px;
  margin-bottom: 5px;
}
.flow-properties-page .collapsible-error-panel .panel-toggle {
  font-size: 10px;
  font-weight: bold;
  color: var(--exo-palette-gray-90);
  letter-spacing: 0.025em;
  cursor: pointer;
}
.flow-properties-page .collapsible-error-panel .panel-toggle::after {
  content: 'LESS INFORMATION';
}
.flow-properties-page .collapsible-error-panel .panel-toggle.collapsed::after {
  content: 'MORE INFORMATION';
}
.flow-properties-page .collapsible-error-panel .panel-body {
  padding: 0;
  border-top: 1px solid var(--exo-palette-red-20);
}
.flow-properties-page .collapsible-error-panel .panel-body pre {
  margin: 0;
  border: none;
}
.flow-properties-page .nav-tabs {
  margin-bottom: 15px;
}
input[type='checkbox'].checkbox-margin-left {
  margin-left: 10px;
}
