.toggle {
  appearance: none;
  outline: 0;
  display: inline-flex;
  width: 45px;
  height: 20px;
  position: relative;
  background-color: var(--exo-palette-gray-40);
  border-radius: 10px;
  margin-right: 10px;
  transform: translateY(-1px);
  transition: background-color 0.2s ease-out;
  border: 1px solid transparent;
}
.toggle:focus {
  box-shadow: 0 0 4px var(--exo-palette-aqua-60);
}
.toggle:focus-visible {
  border-color: var(--exo-palette-gray-80);
}
.toggle:after {
  display: block;
  content: '';
  width: 11px;
  height: 11px;
  background-color: #ffffff;
  border-radius: 50%;
  align-self: center;
  transform: translateX(5px);
  transition: transform 0.4s ease;
}
.toggle.is-on {
  background: var(--exo-palette-aqua-60);
}
.toggle.is-on:after {
  transform: translateX(26px);
}
.toggle.disabled {
  cursor: default;
  background: var(--exo-palette-gray-20);
  box-shadow: none;
}
.toggle.disabled:after {
  background: var(--exo-palette-gray-40);
}
