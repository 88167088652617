#root .graph-canvas .map-element rect:not(.hover-out-border),
#root .graph-canvas .map-element foreignObject {
  cursor: move;
}
#root .graph-canvas .map-element.hover:not(.note-element) {
  filter: drop-shadow(0px 0px 10px white) drop-shadow(0px 0px 10px white);
}
#root .graph-canvas .map-element-delete {
  cursor: pointer;
  color: var(--exo-palette-coral-50);
  background: white;
  border-radius: 100%;
  position: absolute;
}
#root .graph-canvas .map-element-hover-wrapper {
  overflow: visible;
}
#root .graph-canvas .map-element-hover-icon {
  cursor: pointer;
  top: 0;
  font-size: 10px;
  position: absolute;
  overflow: visible;
}
#root .graph-canvas .map-element-hover-icon path {
  stroke: white;
  stroke-width: 65px;
  paint-order: stroke;
}
#root .graph-canvas .map-element.drag .map-element-hover-wrapper,
#root .graph-canvas .map-element:not(.hover) .map-element-hover-wrapper {
  display: none;
}
#root .graph-canvas .element-preview .map-element-text {
  position: absolute;
  left: 5px;
  top: 5px;
  height: 50px;
  width: 82px;
}
#root .graph-canvas .map-element-content {
  height: 100%;
  display: flex;
  align-items: center;
}
#root .graph-canvas .map-element-content.note-element-content {
  max-height: 30px;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
#root .graph-canvas .map-element-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  width: 90px;
  justify-content: center;
}
#root .graph-canvas .map-element-developer-name {
  font-size: 9px;
  line-height: 1.3em;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0;
  border: none;
  outline: 0;
}
#root .graph-canvas .group-element-text .map-element-developer-name {
  padding: 2px;
}
#root .graph-canvas .map-element-description {
  font-size: 6px;
  line-height: 1.3em;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  width: fit-content;
  margin-top: 1px;
}
#root .graph-canvas .map-element-icon {
  top: 0;
  margin-left: 2px;
  margin-right: 6px;
  font-size: 12px;
  flex: none;
}
#root .graph-canvas .color-white {
  color: white;
}
