.expandable-area {
  border: 1px solid var(--exo-palette-gray-20);
}
.expandable-header {
  cursor: pointer;
}
.expand-show {
  transition: max-height 0.5s ease-in-out;
  overflow: auto;
}
.expand-show .table-wrapper {
  overflow-y: auto;
}
.expand-hide {
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
  max-height: 0;
  padding: 0px 15px 0px 15px;
}
.expandable-area-window-large {
  max-height: 72%;
  overflow: auto;
}
.expandable-area-window {
  max-height: 38%;
  overflow: auto;
}
