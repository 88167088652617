.tenant-select a {
  color: inherit;
}
.tenant-select .form-control {
  margin-bottom: 1em;
}
.tenant-select .list-group-item,
.tenant-select .list-group-item .list-group-item-heading {
  color: var(--exo-color-font);
}
.tenant-select .list-group-item-heading {
  margin: 0 0 0.5em;
}
.tenant-select .list-group-item-text {
  margin: 0.25em 0;
}
.tenant-select .list-group-item {
  background: #fff;
  width: 100%;
  text-align: left;
  border: 1px solid var(--exo-palette-gray-30);
  margin-bottom: 0.5em;
  border-radius: var(--exo-radius-standard);
  padding: 1em;
}
.tenant-select .list-group-item:hover,
.tenant-select .list-group-item:focus {
  background-color: var(--exo-palette-aqua-10);
  border-color: var(--exo-palette-gray-50);
}
.tenant-select .active,
.tenant-select .active .list-group-item-heading {
  color: var(--exo-color-font-inverse);
}
.tenant-select .active {
  background-color: var(--exo-color-background-selected);
  border-color: var(--exo-color-background-selected);
}
.tenant-select .active:hover,
.tenant-select .active:focus {
  background-color: var(--exo-color-background-selected-hover);
  border-color: var(--exo-color-background-selected-hover);
}
.tenant-select .modal-body {
  height: calc(85vh - 150px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.tenant-select .modal-body > div {
  display: flex;
  flex-direction: column;
}
.tenant-select .tenant-last-login {
  text-align: right;
}
