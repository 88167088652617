.identity-prodiver-saml-metadata {
    display: block;
}

.identity-prodiver-saml-metadata-environments {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    & > a {
        margin-bottom: 0.5em;
    }
}
