.flow-editor .graph-canvas-wrapper .graph-context-menu {
  display: flex;
  visibility: visible;
  position: fixed;
  flex-flow: column nowrap;
  color: #262626;
  background-color: var(--exo-palette-gray-10);
  border: 1px solid #cccccc;
  box-shadow: 0 5px 10px -3px #00000077;
  border-radius: var(--exo-radius-standard);
  overflow: hidden;
}
.flow-editor .graph-canvas-wrapper .graph-menu-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  color: #262626;
  text-align: start;
  border: none;
  outline: none;
  background-color: transparent;
}
.flow-editor .graph-canvas-wrapper .graph-menu-item:focus,
.flow-editor .graph-canvas-wrapper .graph-menu-item:hover {
  color: var(--exo-palette-gray-10);
  background-color: var(--exo-palette-gray-40);
}
.flow-editor .graph-canvas-wrapper .graph-menu-item[disabled] {
  color: gray;
}
.flow-editor .graph-canvas-wrapper .graph-menu-item[disabled]:hover {
  cursor: default;
  background-color: var(--exo-palette-gray-10);
}
.flow-editor .graph-canvas-wrapper .menu-item-icon {
  flex: 0 0 20px;
  font-size: 20px;
}
.flow-editor .graph-canvas-wrapper .menu-item-label {
  flex: 1 1 auto;
  font-size: 14px;
}
.flow-editor .graph-canvas-wrapper .menu-item-shortcut {
  margin-left: 30px;
  font-size: 12px;
  opacity: 0.66;
}
.flow-editor .graph-canvas-wrapper .graph-context-menu-separator {
  margin: 0;
  border: 0;
  border-top: 1px solid #cccccc;
}
