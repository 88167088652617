.flow-editor .sidebar .sidebar-element {
  cursor: -webkit-grab;
  cursor: grab;
}
.flow-editor .flow-modal-standalone .modal-body {
  height: calc(85vh - 150px);
  overflow-y: auto;
}
.flow-editor .modal {
  visibility: hidden;
}
.flow-editor.active .modal {
  visibility: visible;
}
.flow-editor-details {
  font-size: 13px;
  bottom: 0;
  right: 65px;
  padding: 0.5em;
  background: #fdfdfd;
  display: flex;
  flex-direction: row;
  position: relative;
  left: 0;
  margin: 0;
  background: whitesmoke;
  border-top: 1px solid #cccccc;
}
.flow-editor-details > span + span {
  margin-left: 1em;
}
.flow-editor-details > .flow-last-modified {
  flex-grow: 1;
  text-align: center;
}
.flow-editor-details.new-canvas > .flow-last-modified {
  text-align: end;
}
.flow-editor .keyboard-focused-map:focus-visible {
  outline: 1px solid black;
  outline-offset: 5px;
}
.flow-editor .keyboard-focused-group:focus-visible {
  outline: 1px solid black;
}
.flow-editor .keyboard-focused-selected:focus-visible {
  outline: 1px dashed black;
}
.flow-editor .new-element-keyboard-drag {
  outline: 1px dashed black;
  outline-offset: 5px;
}
html[data-darkreader-scheme='dark'] .swimlane .group-element-border {
  filter: brightness(0.65) contrast(1.3);
}
html[data-darkreader-scheme='dark'] .group .group-element-border {
  filter: brightness(0.509) contrast(1.5);
}
html[data-darkreader-scheme='dark'] .graph-canvas pattern > circle {
  filter: invert(100%);
}
html[data-darkreader-scheme='dark'] .outcome {
  filter: invert(100%) contrast(1.234) brightness(3.4) saturate(0);
}
html[data-darkreader-scheme='dark'] .outcome.out-of-search {
  filter: invert(100%) contrast(1.234) brightness(3.4) saturate(0) opacity(0.3);
}
html[data-darkreader-scheme='dark'] .map-element-hover-icon {
  filter: invert(100%) contrast(1.3) brightness(11) saturate(0);
}
html[data-darkreader-scheme='dark'] #root .graph-canvas .map-element.hover:not(.note-element) {
  filter: drop-shadow(0px 0px 10px #1e2021) drop-shadow(0px 0px 10px #1e2021);
}
.flow-canvas-history-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.flow-canvas-wrapper {
  height: 100%;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}
.flow-canvas-wrapper.debug-left {
  flex-direction: row-reverse;
}
.flow-canvas-wrapper.debug-bottom {
  flex-direction: column;
}
.flow-canvas-wrapper.debug-right {
  flex-direction: row;
}
