.import-export {
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow: auto;

    .import-export-tooltip {
        color: black;
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        max-width: 340px;
        padding: 10px;
        z-index: 1000;

        .import-export-tooltip-arrow {
            position: absolute;
            background: white;
            width: 16px;
            height: 16px;
            transform: rotate(45deg);
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            border-left: 1px solid rgba(0, 0, 0, 0.2);
            z-index: -1;
        }
    }

    .share {
        margin-right: 5px;
    }

    .file-drop {
        max-width: 45rem;
    }
}

#root .import-export .form-control.sharing-token {
    width: 100%;
    max-width: 43rem;
    display: block;
    height: var(--exo-spacing-x-large);
}

.language-select,
.source-language-display,
.select-flow,
.import-upload,
.import-token,
.select-placeholder-type,
.placeholder-text {
    width: 100%;
    max-width: 45rem;
    display: block;
    min-height: var(--exo-spacing-x-large);
    margin-bottom: 5px;
}

.import-upload {
    height: 80px;
    border: 2px dashed #ddd;
    margin: 1em 0;
    text-align: center;
    color: #888;
    display: flex;
    align-items: center;
    justify-content: center;
}

.help-text {
    font-size: 0.7rem;
    margin-left: 0.5rem;
    margin-top: 0.6rem;
}
