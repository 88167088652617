#root .tabs {
  height: 100%;
}
#root .tab-content-wrapper {
  overflow: hidden;
  flex: 1;
  border-left: 1px solid #cccccc;
  display: flex;
}
#root .tab-outer {
  background-color: var(--exo-palette-gray-10);
}
#root .tab-header {
  grid-area: header;
  background-color: var(--exo-palette-brand);
  display: flex;
  height: 56px;
}
#root .tab-header .user-settings-btn {
  height: 100%;
  font-size: 16px;
  color: #ffffff;
  width: 56px;
}
#root .tab-header .user-settings-btn:hover {
  background: var(--exo-color-background-action-hover);
  color: #ffffff;
  outline: 1px solid #ffffff;
  outline-offset: -4px;
}
#root .tab-header-admin:nth-child(2) {
  flex-grow: 1;
  min-width: 0;
}
#root .tab-header-admin {
  align-items: center;
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
#root .tab-header-admin > li {
  height: 100%;
}
#root .tab-header-items-wrapper {
  display: flex;
  margin-top: 1em;
  border-bottom: 1px solid #cccccc;
}
#root .tab-header-items {
  display: flex;
  min-width: 0;
  height: 3rem;
}
#root .tab-header-item {
  padding: 0.5em 1em;
  background-color: var(--exo-palette-gray-20);
  color: var(--exo-palette-brand);
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin-top: 0.7em;
  margin-right: 0.2em;
  transition: margin 0.2s ease-out;
  cursor: default;
  border-top: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 0 none;
  border-left: 1px solid #cccccc;
  border-radius: var(--exo-radius-standard) var(--exo-radius-standard) 0 0;
  width: 200px;
  min-width: 0px;
}
#root .tab-header-item.icon-only {
  padding: 0;
}
#root .tab-header-item.active.icon-only > :not(.btn-icon) {
  display: none;
}
#root .tab-header-item.active {
  background-color: var(--exo-palette-white);
  margin-top: 0.15em;
  margin-bottom: -2px;
  transition: margin-top 0.2s ease-out;
  position: relative;
  border-color: #cccccc #cccccc #ffffff;
  box-shadow: none;
}
#root .tab-header-item.active:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: var(--exo-palette-aqua-50);
  border-radius: var(--exo-radius-standard) var(--exo-radius-standard) 0 0;
  content: '';
}
#root .tab-header-item-label {
  font-family: var(--default-heading-font-family);
  letter-spacing: 0.015em;
  font-size: 1.15em;
  margin: 0 0.5em;
  display: inline-block;
  text-align: center;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  line-height: 1.5em;
  width: 100%;
  user-select: text;
}
#root .tab-header-item .tab-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  min-width: 20px;
  max-width: 20px;
  border-radius: 100%;
}
#root .tab-header-item .tab-icon-wrapper:hover {
  border-radius: 100%;
  background-color: var(--exo-palette-coral-40);
  color: var(--exo-palette-white);
}
#root .tab-header-item .tab-icon {
  font-size: 0.9rem;
  margin-left: 1px;
}
#root .tab-items {
  flex-grow: 1;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
#root .tab-item-enter {
  transform: translateX(100%);
}
#root .tab-item-enter.tab-item-enter-active {
  transform: translateX(0%);
  transition: transform 0.5s ease-in;
}
#root .tab-item-leave {
  transform: translateX(0%);
}
#root .tab-item-leave.tab-item-leave-active {
  transform: translateX(100%);
  transition: transform 0.5s ease-in;
}
#root .logout {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 0.4em;
  margin: 0 0.8em;
}
#root .logout-btn {
  font-family: var(--exo-font-family);
  font-size: 16px;
  font-weight: 700;
  color: var(--exo-palette-white);
  padding: 8px 16px;
  background: none;
  border: solid 1px var(--exo-palette-white);
  border-radius: var(--exo-radius-standard);
}
#root .logout-btn:hover {
  background: var(--exo-color-background-action-hover);
}
