.debug-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--exo-color-background);
  color: var(--exo-color-font);
}
.debug-panel .drag-handle {
  width: 100%;
  height: 4px;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0 none;
  cursor: ns-resize;
  border-top: 1px solid var(--exo-color-border);
}
.debug-panel .drag-handle.left {
  border-right: 1px solid var(--exo-color-border);
}
.debug-panel .drag-handle.right {
  border-left: 1px solid var(--exo-color-border);
}
.debug-panel .drag-handle.left,
.debug-panel .drag-handle.right {
  height: 100%;
  width: 4px;
  cursor: ew-resize;
}
.debug-panel .drag-handle:hover {
  background: var(--exo-palette-aqua-30);
  border: 0 none;
}
.debug-panel .dropdown-list-wrapper {
  top: 116%;
  overflow: hidden;
  border-radius: var(--exo-radius-standard) 0 var(--exo-radius-standard) var(--exo-radius-standard);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  width: 200px;
  right: 5px;
  z-index: 7;
}
.debug-panel .dropdown-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em;
  text-decoration: none;
  border: 1px solid transparent;
  width: 100%;
  background-color: var(--exo-color-background-action);
}
.debug-panel .dropdown-link:hover {
  background-color: var(--exo-color-background-action-hover);
}
.debug-panel.left {
  flex-direction: row-reverse;
}
.debug-panel.right {
  flex-direction: row;
}
.debug-panel-content {
  display: flex;
  width: 100%;
  height: 100%;
}
.debug-panel-content.vertical {
  flex-direction: column;
}
.column-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.column-pane.right-column-pane {
  flex-direction: row;
}
.column-pane.vertical {
  width: 100%;
  border-right: 0;
}
.column-pane.vertical.right-column-pane {
  flex-direction: column;
}
.sub-drag-handle {
  width: 4px;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
  margin-right: auto;
  background: transparent;
  border: 0 none;
  border-right: 1px solid var(--exo-color-border);
  cursor: ew-resize;
}
.sub-drag-handle.left,
.sub-drag-handle.right {
  width: 100%;
  height: 4px;
  border: 0 none;
  border-bottom: 1px solid var(--exo-color-border);
  cursor: ns-resize;
}
.sub-drag-handle:hover {
  background: var(--exo-palette-aqua-30);
  border: 0 none;
}
.debug-header {
  border-bottom: 1px solid var(--exo-color-border);
  height: 50px;
  align-items: center;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
}
.debug-tab {
  background-color: transparent;
  border: 0 none;
  position: relative;
  height: 100%;
}
.debug-tab.active::before {
  background-color: var(--exo-palette-aqua-30);
  position: absolute;
  height: 4px;
  bottom: 0;
  content: '';
  left: 0;
  right: 0;
}
.debug-tabs {
  flex-grow: 1;
}
.breakpoint-control {
  width: 30%;
}
.debug-action-buttons {
  margin-left: auto;
}
.add-breakpoint-panel {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border-bottom: 1px solid var(--exo-color-border);
  padding: 1em;
  background-color: var(--exo-color-background);
  z-index: 1000;
}
.debug-tab-content {
  position: relative;
}
.call-stack-item {
  border: 0 none;
  background: transparent;
  border-bottom: 1px solid var(--exo-color-border);
  display: flex;
  width: 100%;
  padding: 1em;
  gap: 1em;
  align-items: flex-end;
}
.call-stack-item:hover {
  background: var(--exo-color-background-selected-hover);
}
.call-stack-item.is-selected {
  background: var(--exo-color-background-selected);
}
.call-stack-outer {
  position: relative;
  overflow: auto;
}
.call-stack-inner {
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.debug-tabs-inner {
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 40px;
  right: 0;
  left: 0;
}
.call-stack-footer {
  border-top: 1px solid var(--exo-color-border);
}
.has-changed {
  opacity: 0.2;
}
.breakpoint-list {
  min-width: auto;
}
.breakpoint-marker {
  background-color: green;
  position: absolute;
  width: 100%;
  height: 16px !important;
  opacity: 0.5;
}
.root-faults-found {
  color: var(--exo-color-background-danger-strong);
}
.break-points-hit {
  color: var(--exo-color-font-success);
}
.add-breakpoint-header {
  color: var(--exo-color-font-secondary);
}
.breakpoint-value-picker {
  margin-bottom: var(--exo-spacing-medium);
}
#root .breakpoint-value-picker .value-selector-selected .btn-default {
  color: var(--exo-color-font-secondary);
}
.breakpoint-value-picker .value-selector-values-option:hover {
  background: transparent;
}
@keyframes fadeAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.callstack-waiting {
  color: var(--exo-color-font-secondary);
  font: var(--exo-text-label-medium-semi-bold);
  -webkit-animation: fadeAnimation 2.5s infinite;
  -moz-animation: fadeAnimation 2.5s infinite;
  -o-animation: fadeAnimation 2.5s infinite;
  animation: fadeAnimation 2.5s infinite;
}
.short-col {
  width: 20%;
}
.callstack-header {
  min-height: 50px;
  padding-left: 1em;
  font-size: 16px;
}
