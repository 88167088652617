.authorization-config .search {
  position: relative;
  height: 40px;
}
.authorization-config .search-form-wrapper .form-group {
  margin-top: 5px;
}
.authorization-config .search-form-wrapper {
  position: absolute;
  width: 100%;
}
.authorization-config .search-form-wrapper.active {
  background: #f5f5f5;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
  box-shadow: var(--exo-box-shadow-weak);
}
.authorization-config .search-form-wrapper.active .form-group {
  margin: 5px;
}
.authorization-config .search-inner {
  border-top: #dbdbdb solid 1px;
}
.authorization-config .search-inner p {
  margin-left: 2em;
}
.authorization-config .search-results {
  background: #ffffff;
}
.authorization-config .search-results .wait-container-wrapper {
  position: relative;
  height: 100px;
}
.authorization-config .search-results-inner {
  display: flex;
  padding: 0 2em;
}
.authorization-config .search-results-column {
  width: 50%;
}
.authorization-config .search-results-footer {
  background: #f5f5f5;
  padding: 5px;
  border-top: 1px solid #dbdbdb;
  display: flex;
  justify-content: flex-end;
}
.authorization-config .search-wrapper {
  min-height: 215px;
}
