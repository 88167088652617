.page-edit {
  display: flex;
  height: 100%;
  width: 100%;
}
.page-edit-canvas-wrapper {
  display: flex;
  flex-grow: 1;
  overflow: auto;
  padding: var(--exo-spacing-standard);
}
.page-edit.is-mobile-preview .page-edit-canvas-wrapper {
  max-width: 422px;
  margin: var(--exo-spacing-none) auto;
  margin-top: var(--exo-spacing-x-large);
  margin-bottom: var(--exo-spacing-x-large);
  border-radius: var(--exo-spacing-standard);
}
.drag-component {
  padding: var(--exo-spacing-x-small);
  font-size: var(--exo-font-size-x-large);
  width: 100%;
  text-align: left;
  background: none;
  border: 0 none;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: grab;
  gap: var(--exo-spacing-standard);
}
.drag-component span {
  font-size: var(--exo-font-size-small);
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.drag-component .runtime-marker {
  margin-left: auto;
}
.drag-component:disabled {
  cursor: not-allowed;
  background: var(--exo-palette-gray-10);
}
.drag-component:not(:disabled):hover {
  background: var(--exo-palette-aqua-30);
}
.root-container {
  margin: var(--exo-spacing-none);
  border: 1px solid var(--exo-palette-gray-30);
  height: 100%;
  width: 100%;
}
.root-container.page-container-outer {
  margin: var(--exo-spacing-none);
}
.root-container-inner {
  margin: var(--exo-spacing-none);
  height: 100%;
  overflow: auto;
}
.root-container-inner.active {
  background: var(--exo-palette-white);
}
.component-preview-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.component-preview-image {
  max-width: 298px;
  height: 218px;
  width: 100%;
}
.custom-page-component-preview.component-preview-image {
  height: auto;
}
.page-element-arrow {
  position: absolute;
  display: none;
  color: var(--exo-palette-blue-50);
}
.page-element-arrow-top {
  left: 50%;
  transform: translateX(-50%);
  top: -14px;
}
.page-element-arrow-top-inner {
  top: 2px;
}
.page-element-arrow-bottom {
  left: 50%;
  transform: translateX(-50%);
  bottom: -14px;
}
.page-element-arrow-bottom-inner {
  bottom: 2px;
}
.page-element-arrow-left {
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
}
.page-element-arrow-left-inner {
  left: 2px;
}
.page-element-arrow-right {
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
}
.page-element-arrow-right-inner {
  right: 2px;
}
.page-element-arrow.active {
  display: block;
}
.page-edit-canvas-wrapper > .page-container {
  background: var(--exo-palette-white);
}
.page-container-outer {
  flex: 1;
  margin: var(--exo-spacing-x-small);
  background: var(--exo-palette-white);
  border-radius: var(--exo-radius-standard);
  min-width: 0;
  position: relative;
  min-height: min-content;
}
.page-container-outer.dragging {
  background-color: var(--exo-palette-gray-10);
  border-radius: var(--exo-radius-standard);
}
.page-container-outer.preview {
  margin: var(--exo-spacing-none);
  border-radius: var(--exo-radius-standard);
}
.page-container {
  flex: 1;
  border-radius: var(--exo-radius-standard);
  outline-offset: -2px;
  cursor: grab;
}
.root-container > div > .page-container {
  cursor: default;
}
.page-container:not(.root-container-inner, .hover, .preview) {
  outline: 1px dashed var(--exo-palette-gray-30);
  outline-offset: 1px;
}
.page-component.is-dragging-over {
  outline: 1px dashed var(--exo-palette-gray-30);
  outline-offset: 1px;
}
.page-container.hover {
  outline: 2px solid var(--exo-palette-aqua-30);
  outline-offset: -2px;
}
.page-container:focus {
  outline: 2px solid var(--exo-palette-aqua-30);
  outline-offset: -2px;
}
.page-container.active {
  outline: 2px solid var(--exo-palette-aqua-60);
  outline-offset: -2px;
}
.page-container.active:focus {
  outline: 2px solid var(--exo-palette-aqua-30);
  outline-offset: -2px;
}
.page-container.preview {
  outline: 0 none;
}
.page-container.edge-top {
  box-shadow: 0 -3px 1px var(--exo-palette-blue-30);
}
.page-container.edge-bottom {
  box-shadow: 0 3px 1px var(--exo-palette-blue-30);
}
.page-container.edge-left {
  box-shadow: -3px 0 1px var(--exo-palette-blue-30);
}
.page-container.edge-right {
  box-shadow: 3px 0 1px var(--exo-palette-blue-30);
}
.page-container.edge-all {
  outline: 2px solid var(--exo-palette-blue-30);
}
.page-container-header {
  visibility: visible;
  height: 28px;
  position: relative;
  padding: 0 var(--exo-spacing-x-small);
  min-width: 0;
  display: flex;
  align-items: flex-end;
}
.page-container-body {
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--exo-spacing-x-small);
  overflow: auto;
}
.page-container-body.page-component-body {
  min-height: 0;
}
.page-container-body.page-container-row {
  flex-direction: column;
}
.page-container-body.page-container-column {
  flex-direction: row;
}
.page-container-body.preview {
  min-height: 0;
  padding: 0;
}
.page-edit.is-mobile-preview .page-container-column {
  flex-direction: column;
}
.page-container-row > .page-container {
  margin-right: var(--exo-spacing-2x-small);
  margin-bottom: var(--exo-spacing-2x-small);
  margin-left: var(--exo-spacing-2x-small);
}
.page-container-column > .page-container {
  margin-right: var(--exo-spacing-2x-small);
  margin-left: var(--exo-spacing-2x-small);
  margin-bottom: var(--exo-spacing-2x-small);
}
.container-type {
  color: var(--exo-palette-gray-90);
  font-size: var(--exo-font-size-micro);
}
.page-container-header.preview {
  display: none;
}
.container-header-actions {
  display: none;
  gap: var(--exo-spacing-x-small);
}
.container-header-actions-controls {
  gap: var(--exo-spacing-x-small);
}
.page-element-title {
  color: var(--exo-palette-aqua-60);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  display: flex;
  align-items: center;
  gap: var(--exo-spacing-2x-small);
}
.page-container.active > .arrow-wrapper > .page-container-inner > .page-container-header > .container-header-actions,
.page-container.hover > .arrow-wrapper > .page-container-inner > .page-container-header > .container-header-actions {
  display: flex;
  align-items: center;
}
.page-element-title svg {
  font-size: var(--exo-font-size-large);
}
.page-component-preview-label-required::after {
  content: '*';
  margin-left: var(--exo-spacing-2x-small);
}
