#root .tenant-page .form-group {
  max-width: 48rem;
}
#root .tenant-page .security-ip-range {
  padding-left: 20px;
  padding-bottom: 20px;
}
#root .tenant-page .security-ip-range:not([hidden]) {
  display: inline-flex;
  flex-direction: column;
}
#root .tenant-page .add-range {
  align-self: flex-start;
}
#root .tenant-page .restriction-widget {
  max-width: 500px;
  margin-right: 30px;
}
#root .tenant-page .restriction-widget .add-restriction {
  margin-left: 8px;
}
#root .tenant-page .restriction-widget .region-restriction-list {
  margin-left: 20px;
}
#root .tenant-page .SAML-settings .restriction-widget {
  max-width: none;
  margin-right: 0;
}
#root .tenant-page .SAML-settings .restriction-widget .add-restriction {
  height: auto;
  min-height: auto;
  border: 0 none;
}
#root .tenant-page .SAML-settings p.help-block {
  max-width: 100%;
}
#root .tenant-page .SAML-settings .certificate {
  font-family: monospace;
  resize: vertical;
}
#root .tenant-page .SAML-settings .login-url {
  margin: 0 0 5px;
}
#root .column {
  flex-basis: 10%;
}
#root .column-one {
  flex-basis: 25%;
}
#root .column-two {
  flex-basis: 33%;
}
#root .tenant-tab {
  background: #efefef;
  border-top: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  margin: 0 0.5em -1px 0;
  padding: 0.5em 1em;
  color: #10639b;
}
#root .tenant-tab.active {
  background: #ffffff;
  border-bottom-color: #fff;
}
#root .tenant-tab:focus {
  outline: none;
}
#root .tenant-tabs {
  margin: 1em;
  border-bottom: 1px solid #e7e7e7;
}
#root .add-integration-account-modal-footer {
  display: flex;
  justify-content: end;
  gap: 12px;
}
