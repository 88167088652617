#root .page-wrapper-breadcrumbs .breadcrumbs-list,
#root .page-wrapper-breadcrumbs .breadcrumbs-item {
  list-style: none;
  margin: 0;
  padding: 0;
}
#root .page-wrapper-breadcrumbs .breadcrumbs-list {
  display: flex;
  flex-flow: row nowrap;
  margin: 15px 0 0 0;
}
#root .page-wrapper-breadcrumbs .breadcrumbs-item {
  flex: 0 0 auto;
}
#root .page-wrapper-breadcrumbs .breadcrumbs-link {
  color: var(--exo-palette-aqua-50);
  text-decoration: underline;
  padding: 0;
  line-height: 1;
  border: none;
  background-color: transparent;
}
