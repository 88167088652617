.navigation-item {
  white-space: nowrap;
}
.navigation-item.dropdown {
  max-width: 100%;
}
.navigation-item .click-wrapper .item-wrapper {
  word-wrap: break-word;
  white-space: normal;
}
.navigation-item .dropdown-menu {
  max-width: 250px;
}
.navbar-nav.list-wrapper {
  max-width: 100%;
}
.navbar-nav .add-item {
  color: #ffffff;
  font-size: 1.5em;
  text-align: center;
  vertical-align: middle;
}
.navbar-nav .icon-wrapper {
  position: relative;
  display: block;
  color: #9d9d9d;
  padding: 15px 15px;
}
.navbar-nav .icon-wrapper:hover {
  cursor: pointer;
  color: #fff;
}
.navbar-nav .dropdown .item-wrapper {
  position: relative;
  display: block;
  color: #9d9d9d;
  line-height: 20px;
  padding: 15px 15px;
}
.navbar-nav .dropdown .item-wrapper:hover {
  cursor: pointer;
  color: #fff;
}
.navbar-nav .dropdown .dropdown-menu .icon-wrapper {
  position: relative;
  display: block;
  color: #262626;
  padding: 6px 20px;
}
.navbar-nav .dropdown .dropdown-menu .icon-wrapper:hover {
  cursor: pointer;
  color: #262626;
  background-color: #f5f5f5;
}
.navbar-nav .dropdown .dropdown-submenu .item-wrapper {
  position: relative;
  display: block;
  height: 35px;
  color: #262626;
  line-height: 20px;
  padding: 6px 15px;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navbar-nav .dropdown .dropdown-submenu .item-wrapper:hover {
  cursor: pointer;
  color: #262626;
  background-color: #f5f5f5;
}
.navbar-nav .dropdown .dropdown-submenu .item-wrapper:after {
  display: block;
  position: absolute;
  right: 8px;
  top: 11px;
  content: ' ';
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
}
.navbar-nav .dropdown .dropdown-submenu:hover > .click-wrapper > .dropdown-menu {
  display: block;
  margin-top: -36px;
  margin-left: 100%;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.navbar-nav .dropdown:hover > .click-wrapper > .dropdown-menu {
  margin-top: 0px;
  display: block;
}
.navigation-input {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}
.navigation-input input {
  width: 250px;
}
.navigation-input select {
  width: 250px;
}
.navigation-input label:after {
  content: '*';
  color: red;
}
.navigation-input .input-group-wrapper {
  display: flex;
}
.navigation-input .input-group-wrapper .input-group-item {
  flex: 1 1 0;
  margin-right: 20px;
}
.navigation-input .input-group-wrapper .input-group-item #navigate-to {
  max-width: none;
}
.form-enter {
  height: 0;
  overflow: hidden;
}
.form-enter.form-enter-active {
  height: 311px;
  transition: height 0.3s ease-in;
}
.form-leave {
  height: 311px;
  overflow: hidden;
}
.form-leave.form-leave-active {
  height: 0;
  transition: height 0.3s ease-in;
}
.nav-details-bar {
  display: flex;
}
.nav-details-bar .form-group {
  margin-right: 20px;
}
.section-wrapper {
  margin-bottom: 20px;
}
.section-wrapper .state-wrapper {
  margin-bottom: 20px;
}
.section-wrapper .state-wrapper .state-checkbox {
  margin-left: 5px;
}
.table-container .items-empty {
  text-align: center;
}
.table-container .items-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1em;
  overflow: hidden;
}
.table-container .items-header .header-btn {
  margin-left: 20px;
}
.table-container .action-column {
  white-space: nowrap;
  width: 1px;
}
