.editor-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0.5rem;
  padding: 0.5rem;
  width: 100%;
  height: 100%;
}
#editor {
  flex-grow: 1;
  border-radius: var(--exo-radius-standard);
}
.editor-header {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  gap: 0.75rem;
}
.editor-header-label {
  font-size: 0.75rem;
}
.select-label {
  margin-bottom: 0;
}
.document-select {
  width: 18.75rem;
}
.document-select-container,
.editor-actions-panel-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
.editor-action-buttons {
  display: flex;
}
