#root .graph-canvas .outcome {
  cursor: pointer;
}
#root .graph-canvas .outcome .dragging {
  pointer-events: none;
}
#root .graph-canvas .outcome-text {
  text-anchor: middle;
  dominant-baseline: central;
  font-size: 9px;
  user-select: none;
  stroke: white;
  paint-order: stroke;
  stroke-width: 5px;
  stroke-linecap: butt;
  stroke-linejoin: round;
}
#root .graph-canvas .outcome-redirect,
#root .graph-canvas .outcome-control-point {
  cursor: move;
}
