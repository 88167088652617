.boomi-agreement-background {
  background-color: var(--exo-palette-gray-20);
  height: 100%;
}
.boomi-agreement-row {
  height: 100%;
}
.boomi-agreement-body {
  width: 420px;
}
.boomi-agreement-title {
  margin-top: 60px;
}
.boomi-agreement-content {
  padding-top: 50px;
  background-color: var(--exo-palette-white);
  overflow: auto;
}
.boomi-agreement-text {
  width: 374px;
  padding-top: 20px;
  padding-bottom: 50px;
}
@media only screen and (max-width: 768px) {
  .boomi-agreement-content {
    padding-top: 50px;
    width: 100%;
  }
  .boomi-agreement-text {
    width: 90%;
  }
  .boomi-agreement-body {
    width: 90%;
  }
}
