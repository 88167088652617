.flow-wrapper .flow-controls-bar {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 1.5rem 0;
}
.flow-wrapper .controls-left {
  display: flex;
  align-items: center;
}
.flow-wrapper .label-filter {
  margin-left: 1rem;
  min-width: 19rem;
}
.flow-wrapper .label-filter:not(:focus-within) {
  border: 1px solid transparent;
}
.flow-wrapper .label-filter:focus-within {
  border: 1px solid var(--exo-palette-black);
  border-radius: var(--exo-radius-standard);
}
.flow-wrapper .user-icon {
  font-size: 1.5rem;
}
.flow-wrapper .getting-started {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}
.flow-wrapper .flow-users-wrapper {
  position: relative;
}
.flow-wrapper .collaboration-users {
  display: flex;
  position: static;
}
.flow-wrapper .collaboration-user {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  font-size: 0.9rem;
  cursor: default;
}
.flow-wrapper .collaboration-user,
.flow-wrapper .collaboration-user:hover {
  transform: none;
}
.flow-wrapper .collaboration-user-2,
.flow-wrapper .collaboration-user-2:hover {
  transform: translateX(-8px);
}
.flow-wrapper .collaboration-user-3,
.flow-wrapper .collaboration-user-3:hover {
  transform: translateX(-16px);
}
.flow-wrapper .collaboration-user-4,
.flow-wrapper .collaboration-user-4:hover {
  transform: translateX(-24px);
}
.flow-wrapper .collaboration-user-5,
.flow-wrapper .collaboration-user-5:hover {
  transform: translateX(-32px);
}
.flow-wrapper .collaboration-user-more,
.flow-wrapper .collaboration-user-more:hover {
  background: var(--exo-palette-gray-20);
  color: var(--exo-palette-black);
}
.flow-wrapper .users-message {
  color: var(--exo-palette-gray-60);
}
