.logo {
  margin-left: 12px;
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  width: 66px;
}
.logo > img {
  margin: 0 6px 0 9px;
  width: 46px;
}
.logo > span {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
}
