#root .map-element-picker-modal.modal-dialog {
  min-width: auto;
  width: 400px;
}
#root .map-element-picker-modal.modal-dialog .modal-button-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
#root .map-element-picker-modal.modal-dialog .modal-button-wrapper .colour-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
#root .map-element-picker-modal.modal-dialog .modal-button-wrapper .colour-group:empty {
  display: none;
}
#root .map-element-picker-modal.modal-dialog .modal-button-wrapper .modal-btn {
  cursor: pointer;
  background: whitesmoke;
  border-radius: 20px;
  padding: 0.5em 1.2em 0.5em 0.5em;
}
#root .map-element-picker-modal.modal-dialog .modal-button-wrapper .modal-btn .modal-item-text {
  display: block;
  margin-left: 10px;
}
