.card {
    font-family: var(--exo-font-family);
    color: var(--exo-color-font);
    background-color: var(--exo-color-background);
    border: 1px solid var(--exo-color-border);
    box-shadow: 0 var(--exo-spacing-3x-small) var(--exo-spacing-2x-small)
        var(--exo-color-shadow-moderate);
    font-size: var(--exo-font-size-small);
    line-height: var(--exo-line-height-body);
    border-radius: var(--exo-radius-large);
    transition: background-color var(--exo-time-standard) ease;
    padding: var(--exo-spacing-standard) var(--exo-spacing-medium);
}

.clipped-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.automapping-type-map {
    display: flex;
    gap: var(--exo-spacing-small);
}

.automapping-type-map h4 {
    margin-top: 0;
}

.automapping-type-map .source-type {
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
}

.automapping-type-map .target-type-container {
    flex-basis: calc((100% / 3) * 2);
    max-width: calc((100% / 3) * 2);
    display: flex;
    gap: var(--exo-spacing-x-large);
}

.automapping-type-map .target-type-matched,
.automapping-type-map .target-type-unmatched {
    width: 50%;
}

.automapping-type-map ol {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}

.automapping-type-map .target-type-matched ol li,
.automapping-type-map .target-type-unmatched ol li,
.automapping-type-map .source-type ol li {
    display: block;
    margin-bottom: 0;
}

.automapping-type-map ol li > ol .property-details {
    border-left: 1px solid var(--exo-palette-gray-20);
    padding-left: var(--exo-font-size-x-small);
}

.automapping-type-map .property-details {
    display: flex;
    font-size: var(--exo-font-size-small);
    margin-bottom: 0;
    padding: var(--exo-spacing-small) 0;
    align-items: center;
}

.automapping-type-map .property-details:empty {
    height: 45px;
    border-left: none;
    margin: 0;
}

.automapping-type-map .property-details .property-name {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-right: var(--exo-spacing-small);
}

.automapping-type-map .property-details .property-name > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: var(--exo-spacing-small);
}

.automapping-type-map .property-details .property-type {
    color: var(--exo-palette-gray-50);
}

.automapping-type-map .property-details .property-match {
    position: absolute;
    height: 2px;
    width: 30px;
    background-color: var(--exo-palette-gray-50);
    margin-left: var(--exo-spacing-small);
}

.automapping-type-map .property-details .property-match-end {
    position: absolute;
    height: 6px;
    width: 6px;
    background-color: var(--exo-palette-gray-50);
    border-radius: 50%;
    display: inline-block;
    top: -2px;
    left: 38px;
}
