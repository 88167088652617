.wide-tiles-container {
  display: flex;
  gap: 1em;
}
.wide-tile {
  flex: 1;
}
.wide-flow-tile {
  min-height: 100%;
  border-radius: 10px;
  box-shadow: 8px 8px 19px -2px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
}
.wide-flow-tile:hover {
  border: 1px solid #127b87;
}
.wide-flow-tile:active {
  border: 1px solid #127b87;
  box-shadow: 0px 0px 4px 4px #b6e5e2;
}
.wide-tile-add {
  align-self: center;
}
.wide-tile-arrow {
  align-self: center;
}
.wide-tile-header {
  padding: 0.5em 1em 0.5em 1em;
}
.wide-tile-header h5 {
  white-space: pre-wrap;
  line-height: 1.2;
  flex: 1;
  margin-top: 8px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wide-tile-header .btn {
  margin-left: 0.5em;
  flex-shrink: 0;
}
.wide-tile-content {
  padding: 0 15px 0px 15px;
}
.wide-tile-footer {
  padding: 15px;
}
.wide-tile-footer .btn {
  width: 0;
  flex: 1;
}
