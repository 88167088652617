.text-editor {
  width: 100%;
  align-items: center;
}
.text-editor .btn-icon {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}
.text-editor:hover .btn-icon {
  opacity: 1;
}
.text-editor-input {
  width: 100%;
  letter-spacing: -0.5px;
}
.text-editor-display {
  border: 2px solid transparent;
}
