.dropdown-box .dropdown-btn {
  background: transparent;
  border: none;
  padding: 0;
}
.dropdown-box .dropdown-list-wrapper {
  display: none;
  position: absolute;
}
.dropdown-box.is-open > .dropdown-list-wrapper {
  display: block;
}
