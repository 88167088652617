#root .progress-bar-outer {
  height: 8px;
  background-color: #ddd;
  width: 100%;
  border-radius: var(--exo-radius-standard);
  background-clip: border-box;
  overflow: hidden;
}
#root .progress-bar-progress {
  height: 100%;
  display: block;
  background-color: var(--exo-palette-brand);
}
#root .progress-bar-complete .progress-bar-progress {
  width: 100%;
  background-color: #5cb85c;
}
#root .progress-bar-error .progress-bar-progress {
  background-color: #b13f56;
}
