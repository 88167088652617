.footer {
  background-color: #efefef;
  border-top: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: var(--default-font-family);
  font-size: 11px;
  line-height: 16px;
  padding: 5px 7px;
}
.footer .footer-item {
  margin-bottom: 0;
  margin-right: 10px;
  padding-left: 9px;
  padding-right: 0;
  border-left: 1px solid #e1e1e1;
}
.footer .footer-item:first-child {
  border-left: 0;
}
.footer .footer-item .footer-link {
  color: #757575;
  color: #212121;
  cursor: pointer;
}
.footer .footer-item .footer-link:hover {
  color: #007db8;
  text-decoration: none;
}
#root .admin-footer {
  display: flex;
  gap: var(--exo-spacing-small);
  padding: var(--exo-spacing-small);
  border-top: 1px solid var(--exo-color-border);
  justify-content: flex-end;
}
#root .admin-footer .btn + .btn {
  margin: 0;
}
