.tab-header-admin .dropdown-box {
  display: flex;
  height: 100%;
  position: relative;
}
.tab-header-admin .dropdown-box .dropdown-btn {
  font-size: 16px;
  font-weight: 700;
  color: var(--exo-palette-white);
  padding: 0 18px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tab-header-admin .dropdown-box .dropdown-btn .label-info {
  padding: 0.4em 0.6em 0.3em;
  margin-left: 0.5em;
  background-color: var(--exo-palette-aqua-30);
  color: var(--exo-palette-gray-90);
}
.tab-header-admin .dropdown-box .dropdown-btn .header-dropdown {
  display: flex;
  align-items: center;
}
.tab-header-admin .dropdown-box .dropdown-btn:hover {
  background: var(--exo-color-background-action-hover);
  outline: 1px solid var(--exo-palette-white);
  outline-offset: -4px;
}
.tab-header-admin .dropdown-box .dropdown-btn-icon {
  margin-left: 0.4rem;
}
.tab-header-admin .dropdown-box.is-open .dropdown-btn {
  background: var(--exo-color-background-action-hover);
  outline: 1px solid var(--exo-palette-white);
  outline-offset: -4px;
}
.tab-header-admin .dropdown-box.is-open .dropdown-btn-icon {
  transform: rotate(180deg);
}
.tab-header-admin .dropdown-btn:focus {
  outline: none;
}
.tab-header-admin .dropdown-list-wrapper {
  top: 100%;
  min-width: 120px;
  overflow: hidden;
  border-radius: var(--exo-radius-standard) 0 var(--exo-radius-standard) var(--exo-radius-standard);
  background-color: var(--exo-palette-white);
  box-shadow: var(--exo-box-shadow-weak);
  width: 352px;
  font-weight: 700;
  font-size: 16px;
}
.tab-header-admin .dropdown-list-wrapper .user-dropdown-content {
  padding: 15px;
  word-wrap: break-word;
}
.tab-header-admin .dropdown-list-wrapper .user-dropdown-content .list-unstyled {
  margin-bottom: 0;
}
.tab-header-admin .dropdown-list-wrapper p {
  margin-top: 0.5em;
  margin-bottom: 0;
  font-weight: 400;
  padding-right: 0.1em;
}
.tab-header-admin .dropdown-list-wrapper .link-emulate {
  font-size: 14px;
  font-weight: 400;
}
.tab-header-admin .dropdown-list-wrapper .dropdown-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 8px 15px 15px;
  text-decoration: none;
  background: var(--exo-palette-white);
  color: var(--exo-palette-gray-80);
  border: 1px solid transparent;
  text-align: left;
  width: 100%;
}
.tab-header-admin .dropdown-list-wrapper .dropdown-link .dropdown-link-icon {
  margin-right: 25px;
}
.tab-header-admin .dropdown-list-wrapper a.dropdown-link svg {
  flex-shrink: 0;
  height: 16px;
  margin-left: 8px;
}
.tab-header-admin .dropdown-list-wrapper a.dropdown-link svg rect {
  fill: var(--exo-palette-white);
}
.tab-header-admin .dropdown-list-wrapper a.dropdown-link svg path {
  fill: var(--exo-palette-gray-80);
}
.tab-header-admin .dropdown-list-wrapper a.dropdown-link .glyphicon {
  height: 16px;
  width: 16px;
}
.tab-header-admin .dropdown-list-wrapper .dropdown-content {
  flex-grow: 1;
}
.tab-header-admin .dropdown-list-wrapper .settings-dropdown-list .dropdown-content {
  border: 0;
}
.tab-header-admin .dropdown-list-wrapper .dropdown-link:focus {
  outline: 1px solid var(--exo-palette-white);
  outline-offset: -4px;
}
.tab-header-admin .dropdown-list-wrapper .dropdown-link:hover,
.tab-header-admin .dropdown-list-wrapper .dropdown-link:focus,
.tab-header-admin .dropdown-list-wrapper .dropdown-link.selected {
  background: var(--exo-palette-aqua-30);
  border: solid 1px var(--exo-palette-brand);
}
.tab-header-admin .dropdown-list-wrapper.notifications-wrapper {
  width: 400px;
  padding: 15px;
  line-height: 1.42857143;
}
.tab-header-admin .dropdown-list-wrapper.dropdown-right {
  right: 0;
}
.header-admin-left .dropdown-box {
  min-width: 0;
}
