#root .add-users-modal-overrides {
  min-width: unset;
  width: min-content;
}
#root .add-users-modal-overrides .add-users-modal-body {
  display: grid;
  grid-template-columns: repeat(2, 315px);
  margin-top: 15px;
}
#root .add-users-modal-overrides .add-users-modal-body .recently-added-users-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  row-gap: 5px;
  height: 400px;
  padding-right: 15px;
  overflow: auto;
  scrollbar-gutter: stable;
  border-radius: var(--exo-radius-standard);
}
#root .add-users-modal-overrides .add-users-modal-body .recently-added-user {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  padding: 5px;
  border-radius: var(--exo-radius-standard);
  background-color: var(--exo-palette-gray-20);
}
#root .add-users-modal-overrides .add-users-modal-body .recently-added-user-detail {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 10px;
}
#root .add-users-modal-overrides .add-users-modal-body .no-recently-added-users-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: larger;
}
