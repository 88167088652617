.admin {
  flex-grow: 1;
  display: none;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
  background: white;
  overflow: hidden;
  position: relative;
}
.admin.active {
  display: flex;
}
.admin .mw-footer {
  display: flex;
  justify-content: flex-end;
  background-color: var(--exo-palette-gray-10);
  border-top: 1px solid #ccc;
}
.admin-transition {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translate3d(0, 0, 0);
  overflow-x: hidden;
}
.admin.in {
  opacity: 1;
  pointer-events: auto;
}
li.heading:empty {
  padding: 0;
}
.admin-page {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 2em;
}
.admin-page-header {
  display: flex;
  justify-content: space-between;
}
.admin-input {
  max-width: 300px;
}
.ace_search_field {
  color: black;
}
.ace_scrollbar-v {
  width: initial !important;
  background-color: #343d46;
}
.ace_scrollbar-h {
  height: initial !important;
  background-color: #343d46;
}
.flow_filters {
  padding-left: 1em;
}
