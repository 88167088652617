.anomaly-detection .anomaly-title {
  display: flex;
  align-items: center;
  gap: var(--exo-spacing-x-small);
}
.anomaly-detection .table-header {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  min-width: var(--exo-size-8);
}
.anomaly-detection .table-header span {
  font-weight: bold;
  width: 100%;
  margin-left: 10px;
}
.anomaly-detection .filter-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--exo-spacing-large);
}
.anomaly-detection .data-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.anomaly-detection .data-filters .form-group {
  min-width: 15rem;
}
.anomaly-detection .date-filters {
  display: flex;
  gap: 15px;
  height: min-content;
  align-items: center;
  margin-top: 21px;
  min-width: 22rem;
  justify-content: flex-end;
}
.anomaly-detection .time-colour {
  background-color: #6dc79f;
}
.anomaly-detection .error-colour {
  background-color: #c73d58;
}
.anomaly-detection .usage-colour {
  background-color: #6ebdf5;
}
.anomaly-detection .bar-chart-section {
  width: 100%;
}
.anomaly-detection .bar-chart-section .type-icon {
  justify-content: flex-end;
}
.anomaly-detection .type-icon {
  display: flex;
  align-items: center;
  gap: var(--exo-spacing-large);
  margin-right: var(--exo-spacing-large);
}
.anomaly-detection .type-icon span {
  display: flex;
  align-items: center;
  gap: var(--exo-spacing-x-small);
}
.anomaly-detection .type-icon ex-icon {
  font-size: var(--exo-font-size-large);
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  aspect-ratio: 1;
}
.anomaly-detection h3 {
  margin-top: 0;
}
.anomaly-detection ex-empty-state {
  margin-top: var(--exo-spacing-large);
  position: relative;
}
.anomaly-detection ex-structured-list-col .details-section {
  display: flex;
  width: calc(100% - var(--exo-spacing-small));
}
.anomaly-detection ex-structured-list-col .details-section .details-summary {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
.anomaly-detection ex-structured-list-col .details-section .details-summary .details-deviation {
  display: flex;
}
.anomaly-detection ex-structured-list-col .details-section .details-summary .details-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
#root label.notification-button {
  display: flex;
  align-items: center;
  gap: var(--exo-spacing-x-small);
  transition: opacity 1s;
}
#root label.notification-button ex-loader {
  width: var(--exo-spacing-medium);
  height: var(--exo-spacing-medium);
  transform: translateY(-30px);
  animation: fadein 1s;
}
#root label.notification-button ex-icon {
  font-size: var(--exo-font-size-medium);
}
#root label.notification-button .toggle {
  transform: none;
}
#root label.notification-button.notification-status-loading {
  opacity: 0.5;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
