.ace_gutter {
  z-index: auto;
}
.player-button-icon {
  margin-right: 0.5rem;
}
.player-preview-panel-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
@media (min-width: 1380px) {
  .player-preview-panel-container {
    margin-left: auto;
  }
}
.player-preview {
  aspect-ratio: 16 / 9;
  height: 70vh;
  border: 1px solid var(--exo-palette-gray-20);
  border-radius: var(--exo-radius-standard);
}
.modal-body.new-player-modal-body {
  overflow-y: visible;
}
.player-preview-modal .player-preview-modal-body {
  max-height: unset;
  margin: 15px;
  padding: 0;
  overflow: auto;
}
.player-preview-modal .modal-dialog {
  width: max-content;
}
