.page-sidebar {
  border-right: solid 1px var(--exo-palette-gray-20);
}
.page-sidebar .sidebar-inner {
  background: none;
}
.page-sidebar .sidebar-inner .sidebar-btn {
  padding: 0;
}
.page-sidebar .sidebar-inner .heading:not(:empty) {
  padding: 0.5em;
}
.sidebar-collapse {
  border: 0;
}
.sidebar-admin {
  background-color: var(--exo-palette-gray-10);
  padding-top: 61px;
}
.sidebar-admin .sidebar-collapse {
  border-top: solid 1px var(--exo-palette-gray-20);
}
.sidebar {
  flex: none;
  width: 192px;
  display: flex;
  flex-direction: column;
  transition: width 0.2s ease;
  overflow: hidden;
}
.sidebar .sidebar-component {
  display: flex;
  cursor: -webkit-grab;
  cursor: grab;
  white-space: nowrap;
}
.sidebar.collapsed .page-editor-sidebar-header > div {
  display: none;
}
.sidebar.collapsed .page-editor-sidebar-header > span {
  display: inline;
}
.sidebar .sidebar-btn {
  background: none;
  border: 0;
  margin: 0;
  width: 100%;
  line-height: 1.25em;
  font-size: 13px;
  transition: background 0.2s ease;
}
.sidebar .sidebar-collapse {
  width: 100%;
  background: none;
  border-bottom: 1px solid var(--exo-palette-gray-20);
  text-align: center;
  white-space: nowrap;
  padding: 0.75em 0;
  cursor: pointer;
}
.sidebar.collapsed {
  transition: width 0.2s ease;
  width: 65px;
}
.sidebar.collapsed .sidebar-inner {
  width: 65px;
}
.sidebar.collapsed .list-unstyled .sidebar-btn {
  transition: background 1s ease;
}
.sidebar.collapsed .list-unstyled .sidebar-btn .sidebar-item-text {
  opacity: 0;
  transition: opacity 0.2s ease;
}
.sidebar.collapsed .heading {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.sidebar.collapsed .glyphicon {
  visibility: visible;
}
.sidebar.scrolling.collapsed {
  width: 65px;
}
.sidebar.scrolling.collapsed .sidebar-inner {
  width: 65px;
}
.sidebar.hidden {
  display: 'none';
}
.sidebar-inner {
  transition: width 0.2s ease;
  width: 192px;
  background-color: var(--exo-palette-gray-10);
  color: var(--exo-palette-brand);
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
}
.sidebar-inner .logo {
  white-space: nowrap;
  overflow: hidden;
  min-height: 80px;
}
.sidebar-inner .list-unstyled {
  margin-bottom: 0;
  flex-grow: 1;
  padding-bottom: 0.5em;
  overflow-x: hidden;
}
.sidebar-inner .list-unstyled .sidebar-btn,
.sidebar-inner .list-unstyled .heading:not(:empty) {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  padding: 13px;
  padding-left: 21px;
  cursor: pointer;
}
.sidebar-inner .list-unstyled .sidebar-btn:hover:not(.heading),
.sidebar-inner .list-unstyled .heading:not(:empty):hover:not(.heading) {
  background-color: var(--exo-palette-aqua-30);
}
.sidebar-inner .list-unstyled .sidebar-btn:hover a,
.sidebar-inner .list-unstyled .heading:not(:empty):hover a {
  color: var(--exo-palette-white);
  text-decoration: none;
}
.sidebar-inner .list-unstyled .sidebar-btn.active,
.sidebar-inner .list-unstyled .heading:not(:empty).active {
  background-color: var(--exo-palette-aqua-30);
  border-left: 5px solid var(--exo-palette-brand);
  padding-left: 16px;
}
.sidebar-inner .list-unstyled .sidebar-btn a,
.sidebar-inner .list-unstyled .heading:not(:empty) a {
  color: lime;
  font-weight: bold;
}
.sidebar-inner .list-unstyled .sidebar-btn .sidebar-item-text,
.sidebar-inner .list-unstyled .heading:not(:empty) .sidebar-item-text {
  opacity: 1;
  transition: opacity 0.2s ease;
}
.sidebar-inner .list-unstyled .anchor-fill {
  padding: 0;
}
.sidebar-inner .list-unstyled .anchor-fill a {
  display: block;
  padding: 1em;
  padding-left: 1.6em;
}
.sidebar-inner .list-unstyled hr {
  width: 200px;
  color: #919191;
  margin: 0.5em 1em;
}
.sidebar-inner .list-unstyled .heading:not(:empty) {
  border-bottom: 1px solid var(--exo-palette-gray-20);
  color: var(--exo-palette-brand);
  cursor: default;
  padding: 0.5em;
}
.sidebar-inner .list-unstyled .glyphicon {
  margin-right: 1em;
}
.sidebar-inner .list-unstyled .backdrop {
  margin-right: 1em;
}
.sidebar-inner .list-unstyled .backdrop .glyphicon {
  margin-right: 0;
}
.sidebar-inner::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.badge {
  position: absolute;
  right: 0;
  min-width: 20px;
  min-height: 20px;
  background: var(--exo-color-background-warning);
  display: flex;
  justify-content: center;
  border-radius: 10px;
  color: var(--exo-color-font);
  align-items: center;
  padding: 0px 5px;
  pointer-events: none;
}
