.process-option {
    padding: 4px;
    padding: 8px 12px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto auto;
    gap: var(--exo-spacing-2x-small);
}

.process-option:hover {
    background-color: #deebff;
}

.process-option.selected {
    background-color: #2684ff;
    color: hsl(0, 0%, 100%);
}

.process-option:not(.selected) .process-option-folder-name,
.process-option:not(.selected) .process-option-account-id {
    color: var(--exo-color-font-secondary);
}

.process-option-folder-name {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.process-option-account-id {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}

.process-option-link {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.process-metadata {
    display: flex;
    gap: var(--exo-spacing-small);
}

.process-metadata-folder-name,
.process-metadata-account-id {
    color: var(--exo-color-font-secondary);
}
