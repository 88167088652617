.history-panel {
  background: var(--exo-color-background-secondary);
  border-left: 1px solid var(--exo-color-border);
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 364px;
  overflow-y: auto;
  position: relative;
}
.history-panel .history-title {
  background: var(--exo-color-background);
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--exo-color-border);
}
.history-panel .history-title h4 {
  margin: 0 10px;
}
.history-panel .compare-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
}
.history-panel .history-before-after {
  width: 100%;
  display: flex;
}
.history-panel .history-before-after .text {
  width: 100%;
}
.history-panel .history-versions {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.history-panel .history-versions .arrow {
  margin: 2px 0;
}
.history-panel .history-versions .history-card {
  background: var(--exo-color-background-secondary);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--exo-color-border);
  width: 100%;
  overflow: hidden;
}
.history-panel .history-versions .history-card .restore-button {
  width: calc(100% - 10px);
  margin: 0 5px 5px 5px;
}
.history-panel .history-versions .history-card .name {
  padding: 5px;
  border-bottom: 1px solid var(--exo-color-border);
  background: var(--exo-color-background);
}
.history-panel .history-versions .history-card .summary {
  padding: 5px 5px 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--exo-font-size-micro);
}
.history-panel .history-versions .history-card .summary:empty {
  display: none;
}
.history-panel .history-versions .history-card .badge-group {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}
.history-panel .arrow,
.history-panel .arrow-placeholder {
  width: 36px;
}
.changes-section-text {
  padding: 20px 15px 0 15px;
}
.scroll-blocker {
  position: sticky;
  width: 100%;
  height: 20px;
  top: 0;
  background: var(--exo-color-background);
  flex-shrink: 0;
  z-index: 1;
}
.changes-grid {
  display: grid;
  grid-template-columns: max-content 1fr 1fr;
  grid-template-rows: auto auto;
  padding: 0 15px 15px 15px;
}
.diff-section {
  grid-column: 2 / -1;
  border-right: 1px solid var(--exo-color-border);
  border-bottom: 1px solid var(--exo-color-border);
  border-bottom-right-radius: var(--exo-radius-standard);
  padding: 15px;
  gap: 15px;
  display: flex;
  flex-direction: column;
}
.diff-section .description {
  display: flex;
}
.diff-section .description span {
  width: 100%;
  white-space: pre;
}
.diff-section .change-titles {
  width: 100%;
  display: flex;
}
.diff-section .selected .change-title {
  background: var(--exo-color-background-selected-weak);
  border-top: 1px solid var(--exo-color-border-tertiary);
}
.diff-section .selected .change-title:first-child,
.diff-section .selected .change-title.no-diff {
  border-left: 1px solid var(--exo-color-border-tertiary);
}
.diff-section .selected .change-title:last-child,
.diff-section .selected .change-title.no-diff {
  border-right: 1px solid var(--exo-color-border-tertiary);
}
.diff-section .selected .change-body {
  border-left: 1px solid var(--exo-color-border-tertiary);
  border-right: 1px solid var(--exo-color-border-tertiary);
  border-bottom: 1px solid var(--exo-color-border-tertiary);
}
.diff-section .change-title {
  width: 100%;
  padding: 10px;
  font-family: var(--exo-font-monospace), consolas, monospace;
  background: var(--exo-color-background-secondary);
  border: 1px solid var(--exo-color-border);
  border-bottom: none;
}
.diff-section .change-title:first-child {
  border-top-left-radius: var(--exo-radius-standard);
}
.diff-section .change-title:last-child {
  border-left: none;
  border-top-right-radius: var(--exo-radius-standard);
}
.diff-section .change-title.no-diff {
  border-top-right-radius: var(--exo-radius-standard);
}
.diff-section .change-body {
  border: 1px solid var(--exo-color-border);
  border-top: none;
  border-bottom-left-radius: var(--exo-radius-standard);
  border-bottom-right-radius: var(--exo-radius-standard);
  overflow: hidden;
}
.diff-section .change-body.added table,
.diff-section .change-body.removed table {
  background: repeating-linear-gradient(45deg, var(--exo-color-background-tertiary), var(--exo-color-background-tertiary) 5px, var(--exo-color-background-secondary) 5px, var(--exo-color-background-secondary) 10px);
}
.diff-section .change-body.added td:nth-child(-n + 3),
.diff-section .change-body.removed td:nth-child(n + 4) {
  visibility: hidden;
}
.sticky-top-section {
  position: sticky;
  top: 15px;
  background: var(--exo-color-background);
  z-index: 2;
}
.left-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  gap: 5px;
  border: 1px solid var(--exo-color-border);
  border-top-left-radius: var(--exo-radius-standard);
  padding: 10px;
}
.sticky-left-section-wrapper {
  border: 1px solid var(--exo-color-border);
  border-top: none;
  border-bottom-left-radius: var(--exo-radius-standard);
  align-self: stretch;
  display: flex;
}
.sticky-left-section {
  position: sticky;
  top: 77px;
  align-self: start;
  width: 100%;
}
.sticky-left-section ex-tree {
  width: calc(100% - var(--exo-spacing-2x-small) - 1px);
  display: block;
}
.header-section {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-top: 1px solid var(--exo-color-border);
  border-bottom: 1px solid var(--exo-color-border);
}
.header-section .collaboration-user {
  flex-shrink: 0;
  transform: none;
  cursor: default;
}
.header-section .text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}
.header-section + .header-section {
  border-left: 1px solid var(--exo-color-border);
  border-right: 1px solid var(--exo-color-border);
  border-top-right-radius: var(--exo-radius-standard);
}
#root .snapshot .btn {
  height: var(--exo-spacing-x-large);
  border-radius: var(--exo-spacing-2x-small);
}
.history-modal {
  display: flex;
  flex-shrink: 0;
}
.history-modal .history-environment {
  padding: 15px 20px 20px 20px;
  flex: 1 1 0px;
}
.history-modal .history-environment:not(:last-child) {
  border-right: 1px solid var(--exo-palette-gray-20);
}
.history-modal .history-environment h4 {
  margin: 0 0 10px 0;
}
.history-modal .history-environment .snapshot-list {
  pointer-events: initial;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  grid-template-columns: auto 1fr;
}
#root .history-modal-dialog {
  width: 90%;
  max-width: 1000px;
}
#root .history-modal-body {
  padding: 0;
}
.snapshot {
  background: var(--exo-color-background);
  position: relative;
  display: grid;
  grid-template-columns: 0fr auto;
  border-radius: var(--exo-radius-standard);
  padding: 10px;
  gap: 10px;
  margin: 0 10px;
  width: calc(100% - 20px);
  border: 1px solid lightgrey;
}
.snapshot:not(.comparable) {
  cursor: default;
}
.snapshot.selected {
  outline-offset: -3px;
  outline: 3px solid var(--exo-palette-blue-60);
  background: var(--exo-palette-blue-10);
}
.snapshot.comparable:not(.selected):hover {
  outline-offset: -2px;
  outline: 2px solid var(--exo-palette-blue-60);
}
.snapshot .collaboration-user {
  transform: none;
  cursor: default;
}
.snapshot .content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}
.snapshot .content .release-name-and-environment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.snapshot .content .release-name-and-environment b {
  text-align: left;
  font-size: var(--exo-font-size-medium);
}
.snapshot .content .button-group {
  display: flex;
  gap: 10px;
}
.snapshot.release:not(:last-child)::after {
  content: '';
  position: absolute;
  width: 4px;
  background: var(--exo-color-border);
  height: 25px;
  bottom: -26px;
  left: 30px;
}
.snapshot:last-child {
  margin-bottom: 10px;
}
.diff > div > div:first-child {
  display: none;
}
.diff > div table {
  min-width: auto;
}
.diff > div table tr:first-child {
  display: none;
}
