#root {
  --gutter: 15px;
}
#root .page-wrapper {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
#root .page-wrapper .page-wrapper-breadcrumbs {
  padding-left: 15px;
  padding-right: 15px;
}
#root .page-wrapper .page-wrapper-header {
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid var(--exo-palette-gray-20);
}
#root .page-wrapper .page-wrapper-header h1 {
  margin: var(--gutter) 0;
}
#root .page-wrapper .page-wrapper-main {
  overflow: hidden;
  display: flex;
  flex: 1;
}
#root .page-wrapper .page-wrapper-menu {
  flex: 1;
  min-width: min-content;
  white-space: nowrap;
  padding: 0;
  height: inherit;
  overflow-y: auto;
  border-right: 1px solid var(--exo-palette-gray-20);
}
@media screen and (max-width: 768px) {
  #root .page-wrapper .page-wrapper-menu {
    border-right: none;
    border-bottom: 1px solid var(--exo-palette-gray-20);
  }
}
#root .page-wrapper .page-wrapper-menu .menu-heading {
  font-size: 12px;
  padding: 0 10px;
}
#root .page-wrapper .page-wrapper-menu .menu-list,
#root .page-wrapper .page-wrapper-menu .menu-item {
  list-style: none;
  margin: 0;
  padding: 0;
}
#root .page-wrapper .page-wrapper-menu .menu-link {
  display: block;
  padding: 5px var(--gutter);
  color: var(--exo-palette-aqua-50);
  text-decoration: none;
}
#root .page-wrapper .page-wrapper-menu .menu-link.active {
  color: var(--exo-palette-white);
  background-color: var(--exo-palette-aqua-50);
}
#root .page-wrapper .page-wrapper-menu .menu-link:hover {
  color: var(--exo-palette-white);
  background-color: var(--exo-palette-aqua-50);
  transition: background-color 200ms ease, color 200ms ease, outline 50ms ease, outline-offset 50ms ease, box-shadow 50ms ease;
}
#root .page-wrapper .page-wrapper-content {
  flex: 6;
  overflow-y: auto;
  padding: 0;
  height: inherit;
  display: flex;
  flex-flow: column nowrap;
}
#root .page-wrapper .page-wrapper-content p.description {
  margin: 10px 0;
  max-width: 120ch;
}
#root .page-wrapper .controls-right {
  display: flex;
}
